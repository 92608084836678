<template>
  <div class="bg-zoom flex flex-col items-center justify-center w-full h-full bg-no-repeat">
    <div 
      class="flex w-full"
      :class="[
        $screen.landscape 
          ? '3xl:mb-12 items-end mb-4' 
          : 'w-full flex-col pt-24'
      ]"
    >
      <div 
        class=""
        :class="[
          $screen.landscape 
            ? 'w-1/2 text-right' 
            : 'w-full text-center'
        ]"
      >
        <h2 
          class="text-blue-dark font-bold leading-none uppercase"
          :class="[
            $screen.landscape 
              ? 'h2-title-bold' 
              : 'h2-title-portrait flex justify-center flex-wrap mb-12'
          ]"
        >
          <span class="text-picton-blue-500" v-if="$i18n.locale === 'uk'">Нам можна</span>
          <span class="text-picton-blue-500" v-if="$i18n.locale === 'ru'">Нам можно</span>
          <br>
          <span v-if="$i18n.locale === 'uk'">довіряти</span>
          <span v-if="$i18n.locale === 'ru'">доверять</span>
        </h2>
      </div>
      <div 
        class="description-big"
        :class="[
          $screen.landscape 
            ? 'w-1/2 max-w-2xl pl-14 pb-2' 
            : 'w-full text-center md:mb-24 mb-96'
        ]"
      >
        <span v-if="$i18n.locale === 'uk'">Але можна й перевірити.</span>
        <span v-if="$i18n.locale === 'ru'">Но можно и проверить.</span>
      </div>
    </div>

    <div 
      class="flex flex-wrap justify-center"
      :class="[
        $screen.landscape 
          ? '3xl:ml-80 4xl:ml-0 3xl:max-w-screen-xl 3xl:w-3/4  w-11/12 mb-12' 
          : 'flex-col mb-16'
      ]"
    >
      
      <!-- Юридична особа -->
      <div class="col">
        <div class="trust-icon">
          <icon-base
            width="50"
            height="84"
            viewBox="0 0 50.6 84.3"
            class="icon-trizub"
            style="color: #ffd500"
            :title="
              $i18n.locale === 'uk'
                ? 'Перевірити на сайті Мін\'юсту'
                : 'Проверить на сайте Минюста'
            "
          ><icon-trizub /></icon-base>
        </div>
        <div class="trust-title">
          <a class="underline"  href="https://usr.minjust.gov.ua/content/free-search">
            <span v-if="$i18n.locale === 'uk'">Перевірити на сайті Мін'юсту</span>
            <span v-if="$i18n.locale === 'ru'">Проверить на сайте Минюста</span>
          </a>
        </div>
        <div class="trust-description">
          <p v-if="$i18n.locale === 'uk'">Приватне підприємство «Студія «Зіна дизайн» працює як юридична особа з&nbsp;2002 року. Наш код ЄДРПОУ: 31784853.</p>
          <p v-if="$i18n.locale === 'ru'">Частное предприятие «Студия «Зина дизайн» работает как юридическое лицо с&nbsp;2002 года. Наш код ЄДРПОУ: 31784853.</p>
        </div>
      </div>

      <!-- Реєстратор доменів -->
      <div class="col">
        <div class="trust-icon">
          <img 
            src="/home/img/domain-ua.png" 
            class="icon-domain"
            :alt="
              $i18n.locale === 'uk'
                ? 'Перевірити в реєстрі домену .UA'
                : 'Проверить в реестре домена .UA'
            "
          >
        </div>
        <div class="trust-title">
          <a class="underline"  href="https://hostmaster.ua/registrars/">
            <span v-if="$i18n.locale === 'uk'">Перевірити в реєстрі домену&nbsp;.UA</span>
            <span v-if="$i18n.locale === 'ru'">Проверить в реестре домена&nbsp;.UA</span>
          </a>
        </div>
        <div class="trust-description">
          <p v-if="$i18n.locale === 'uk'">З 2007 року Студія «Зіна дизайн» є&nbsp;офіційним реєстратором доменів в&nbsp;Україні.</p>
          <p v-if="$i18n.locale === 'ru'">С 2007 года Студия «Зина дизайн» является официальным регистратором доменов в&nbsp;Украине.</p>
        </div>
      </div>
      
      <!-- Prozorro -->
      <div class="col">
        <div class="trust-icon">
          <img 
            src="/home/img/prozorro-logo.png" 
            class="icon-prozorro"
            :alt="
              $i18n.locale === 'uk'
                ? 'Перевірити на prozorro.gov.ua'
                : 'Проверить на prozorro.gov.ua'
            "
          >
        </div>
        <div class="trust-title">
          <a class="underline"  href="https://prozorro.gov.ua/tender/search?query=31784853">
            <span v-if="$i18n.locale === 'uk'">Перевірити на prozorro.gov.ua</span>
            <span v-if="$i18n.locale === 'ru'">Проверить на prozorro.gov.ua</span>
          </a>
        </div>
        <div class="trust-description">
          <p v-if="$i18n.locale === 'uk'">Ми регулярно виграємо громадські тендери на обслуговування сайтів державних організацій.</p>
          <p v-if="$i18n.locale === 'ru'">Мы регулярно выигрываем публичные тендеры на обслуживание сайтов государственных организаций.</p>
        </div>
      </div>

      <!-- Договорір -->
      <div class="col">
        <div class="trust-icon">
          <icon-base
            width="70"
            height="86"
            viewBox="0 0 69.5 85.6"
            class="icon-dogovir"
            style="color: #bac3d4"
            :title="
              $i18n.locale === 'uk'
                ? 'Перевірити типовий договір на розробку сайту'
                : 'Проверить типовой договор на разработку сайта'
            "
          ><icon-dogovir /></icon-base>
        </div>
        <div class="trust-title">
          <a class="underline"  href="https://doc.zina.design/dogovir">
            <span v-if="$i18n.locale === 'uk'">Перевірити договір <span class="3xl:block hidden">на&nbsp;розробку сайту</span></span>
            <span v-if="$i18n.locale === 'ru'">Проверить договор <span class="3xl:block hidden">на&nbsp;разработку сайта</span></span>
          </a>
        </div>
        <div class="trust-description">
          <p v-if="$i18n.locale === 'uk'">Ми укладаємо договір на створення сайту, де є все необхідне: вимоги до якості, гарантія, передача всіх прав на сайт.</p>
          <p v-if="$i18n.locale === 'ru'">Мы заключаем договор на создание сайта, где прописываем всё необходимое: качество, гарантию, ваши права на сайт.</p>
        </div>
      </div>

      <!-- Статут -->
      <div class="col">
        <div class="trust-icon">
          <icon-base
            width="76"
            height="90"
            viewBox="0 0 75.7 90"
            class="icon-statut"
            style="color: #007ec2"
            :title="
              $i18n.locale === 'uk'
                ? 'Перевірити статутні документи'
                : 'Проверить уставные документы'
            "
          ><icon-statut /></icon-base>
        </div>
        <div class="trust-title">
          <a class="underline"  href="https://doc.zina.design/statut">
            <span v-if="$i18n.locale === 'uk'">Перевірити статутні документи</span>
            <span v-if="$i18n.locale === 'ru'">Проверить уставные документы</span>
          </a>
        </div>
        <div class="trust-description">
          <p v-if="$i18n.locale === 'uk'">До договіру на розробку сайту вашому бухгалтеру знадобляться наші статутні документи.</p>
          <p v-if="$i18n.locale === 'ru'">К договору на разработку сайта вашему бухгалтеру потребуются наши уставные документы.</p>
        </div>
      </div>

      <!-- Google + Facebook -->
      <div class="col">
        <div class="trust-icon space-x-4">
          <icon-base
            width="66"
            height="66"
            viewBox="0 0 65.5 65.5"
            class="icon-social"
            :title="
              $i18n.locale === 'uk'
                ? 'Знайдіть нас в Google'
                : 'Найдите нас в Google'
            "
          ><icon-google /></icon-base>
          <icon-base
            width="66"
            height="66"
            viewBox="0 0 65.5 65.5"
            class="icon-social"
            style="color: #1f68e8"
            :title="
              $i18n.locale === 'uk'
                ? 'Знайдіть нас в Facebook'
                : 'Найдите нас в Facebook'
            "
          ><icon-facebook /></icon-base>
        </div>
        <div class="trust-title">
          <a class="underline"  href="https://www.facebook.com/Zina.Design/">
            <span v-if="$i18n.locale === 'uk'">Перевірити та лайкнути&nbsp;👍</span>
            <span v-if="$i18n.locale === 'ru'">Проверить и поставить лайк&nbsp;👍</span>
          </a>
        </div>
        <div class="trust-description">
          <p v-if="$i18n.locale === 'uk'">І, звичайно, ви легко знайдете нас у&nbsp;Google та у&nbsp;Facebook.</p>
          <p v-if="$i18n.locale === 'ru'">И, разумеется, вы легко найдете нас в&nbsp;Google и в&nbsp;Facebook.</p>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
const IconBase = defineAsyncComponent(() => import("@/components/IconBase"));
const IconTrizub = defineAsyncComponent(() => import("@/components/icons/IconTrizub"));
const IconDogovir = defineAsyncComponent(() => import("@/components/icons/IconDogovir"));
const IconStatut = defineAsyncComponent(() => import("@/components/icons/IconStatut"));
const IconFacebook = defineAsyncComponent(() => import("@/components/icons/IconFacebook"));
const IconGoogle = defineAsyncComponent(() => import("@/components/icons/IconGoogle"));
// import IconBase from '@/components/IconBase.vue'
// import IconTrizub from '@/components/icons/IconTrizub.vue'
// import IconDogovir from '@/components/icons/IconDogovir.vue'
// import IconStatut from '@/components/icons/IconStatut.vue'
// import IconFacebook from '@/components/icons/IconFacebook.vue'
// import IconGoogle from '@/components/icons/IconGoogle.vue'
export default {
  name: "TrustBlock",
  components: {
    IconBase,
    IconTrizub,
    IconDogovir,
    IconStatut,
    IconFacebook,
    IconGoogle
  }
};

</script>

<style lang="scss" scoped>

  .col {
    @apply w-80 mb-12;
    @media (orientation: landscape) {
      @apply 3xl:w-80 w-64 mx-12 3xl:mb-8 mb-2;
    }
  }

  .trust-icon {
    @apply flex items-center justify-center;
    height: 90px;
    @media (orientation: landscape) {
      display: none;
    }
    @media (min-width: 1440px) { 
      display: flex;
    }
    height: 50px;
    @media (min-width: 1680px) { 
      height: 90px;
    }
  }

  .icon-trizub {
    @media (orientation: portrait) {
      width: 50px;
    }
    width: 40px;
    @media (min-width: 1680px) { 
      width: 50px;
    }
  }
  .icon-domain {
    @media (orientation: portrait) {
      width: 123px;
    }
    width: 90px; 
    @media (min-width: 1680px) { 
      width: 123px;
    }
  }
  .icon-prozorro {
    @media (orientation: portrait) {
      width: 159px;
    }
    width: 130px;
    @media (min-width: 1680px) { 
      width: 159px;
    }
  }
  .icon-dogovir {
    @media (orientation: portrait) {
      width: 55px;
    }
    width: 40px; 
    @media (min-width: 1680px) { 
      width: 65px;
    }
  }
  .icon-statut {
    @media (orientation: portrait) {
      width: 65px;
    }
    width: 45px; 
    @media (min-width: 1680px) { 
      width: 65px;
    }
  }
  .icon-social {
    @media (orientation: portrait) {
      width: 65px;
    }
    width: 45px; 
    @media (min-width: 1680px) { 
      width: 65px;
    }
  }

  .trust-title {
    @media (orientation: portrait) {
      @apply text-xl font-normal flex justify-center;
    }
    @apply text-blue-dark 3xl:h-14 2xl:h-10 h-14 3xl:mb-2 flex items-center 2xl:justify-center font-bold 2xl:font-normal text-base 3xl:text-xl 4xl:text-2xl 2xl:text-center leading-tight;
  }
  .trust-description {
    @media (orientation: portrait) {
      @apply text-lg;
    }
    @apply leading-snug text-sm 3xl:text-base 4xl:text-lg;
  }

  .bg-zoom {
    background-image: url(/home/img/magnifier-550.webp); 
    @media (orientation: portrait) {
      background-position: left -100px top 280px;
    }
    background-position: left -220px bottom 110px;
    @media (min-width: 1440px) { 
      background-position: left -220px bottom 150px;
    }
    @media (min-width: 1680px) { 
      background-image: url(/home/img/magnifier.webp); 
      background-position: left -180px bottom 150px;
    }
  }

</style>