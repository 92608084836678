<template>
  <div class="bg-keys">
    <div 
      class="flex w-full"
      :class="[
        $screen.landscape 
          ? '3xl:mb-12 items-end mt-20 mb-6' 
          : 'flex-col justify-center pt-28'
      ]"
    >
      <div 
        class=""
        :class="[
          $screen.landscape 
            ? 'w-1/2 text-right' 
            : 'w-full text-center mb-64 lg:mb-0'
        ]"
      >
        <h2 
          class="text-blue-dark font-bold leading-none uppercase"
          :class="[
            $screen.landscape 
              ? 'h2-title-bold ' 
              : 'h2-title-portrait mb-24'
          ]"
        >
          <span class="text-picton-blue-500" v-if="$i18n.locale === 'uk'">Реально</span>
          <span class="text-picton-blue-500" v-if="$i18n.locale === 'ru'">Реально</span>
          <br>
          <span v-if="$i18n.locale === 'uk'">під&nbsp;ключ</span>
          <span v-if="$i18n.locale === 'ru'">под&nbsp;ключ</span>
        </h2>
      </div>
      <div 
        class=""
        :class="[
          $screen.landscape 
            ? 'description-big pl-14 w-1/2 pb-1 3xl:max-w-2xl max-w-lg' 
            : 'description-main mb-16'
        ]"
      >
        <span v-if="$i18n.locale === 'uk'">
          Зіна дизайн — це повний 💪 сервіс створення сайту: з&nbsp;доменом, хостингом, підтримкою та&nbsp;просуванням.
        </span>
        <span v-if="$i18n.locale === 'ru'">
          Зина дизайн — это полный 💪 сервис создания сайта: с&nbsp;доменом, хостингом, поддержкой и&nbsp;продвижением.
        </span>
      </div>
    </div>

    <div 
      class="3xl:mb-32 relative flex justify-center mx-auto mb-20"
      :class="[
        $screen.landscape 
          ? 'circles-container -space-x-6' 
          : 'flex-col -space-y-3'
      ]"
    >
      <div 
        class="circle bg-picton-blue-500"
        :class="[
          $screen.landscape 
            ? '' 
            : ''
        ]"
      >
        <h3 class="circle-title">
          <span v-if="$i18n.locale === 'uk'">Реєстратор доменів</span>
          <span v-if="$i18n.locale === 'ru'">Регистратор доменов</span>
        </h3>
        <div class="circle-text">
          <p v-if="$i18n.locale === 'uk'">Ми офіційний реєстратор доменних імен. Запропонуємо варіанти домену, здійснимо реєстрацію, продовження, зміну власника.</p>
          <p v-if="$i18n.locale === 'ru'">Мы официальный регистратор доменных имен. Предложим варианты домена, выполним регистрацию, продление, смену владельца.</p>
        </div>
      </div>
      <div class="circle bg-gradient-to-b to-picton-blue-500 from-zina-purple">
        <h3 class="circle-title">
          <span v-if="$i18n.locale === 'uk'">Власний хостинг</span>
          <span v-if="$i18n.locale === 'ru'">Собственный хостинг</span>
        </h3>
        <div class="circle-text">
          <p v-if="$i18n.locale === 'uk'">Сервера студії «Зіна дизайн» стоять у&nbsp;найбільшому дата-центрі Києва. Ваш&nbsp;сайт буде працювати швидко і&nbsp;під кваліфікованим наглядом.</p>
          <p v-if="$i18n.locale === 'ru'">Сервера студии «Зина дизайн» стоят в&nbsp;крупнейшем дата-центре Киева. Ваш&nbsp;сайт будет работать быстро и&nbsp;под квалифицированным присмотром.</p>
        </div>
      </div>
      <div 
        class="circle bg-zina-purple"
        :class="[
          $screen.landscape 
            ? '' 
            : ''
        ]"
      >
        <h3 class="circle-title">
          <span v-if="$i18n.locale === 'uk'">SEO та <span class="turnkey__head_hide">контекстна</span> реклама</span>
          <span v-if="$i18n.locale === 'ru'">SEO и <span class="turnkey__head_hide">контекстная</span> реклама</span>
        </h3>
        <div class="circle-text">
          <p 
            :class="[
              $screen.landscape 
                ? 'xl:block hidden' 
                : ''
            ]"
            v-if="$i18n.locale === 'uk'"
          >
            <span 
              :class="[
                $screen.landscape 
                  ? '2xl:inline hidden' 
                  : ''
              ]"
            >
              У нашій команді є дизайнери, програмісти, фахівці з&nbsp;SEO та&nbsp;Google Ads.
            </span> 
            Ми створюємо сайти та приводимо відвідувачів.
          </p>
          <p 
            :class="[
              $screen.landscape 
                ? 'xl:block hidden' 
                : ''
            ]"
            v-if="$i18n.locale === 'ru'">
            <span 
              :class="[
                $screen.landscape 
                  ? 'xl:block hidden' 
                  : ''
              ]"
            >
              В&nbsp;нашей команде дизайнеры, программисты, специалисты по&nbsp;SEO и&nbsp;Google Ads.
            </span>
            Мы&nbsp;создаем сайты и&nbsp;приводим посетителей.
          </p>
        </div>
      </div>
    </div>  
    <!-- <div class="h-1/6"></div> -->
  </div>
</template>

<script>
export default {
  name: "FullServiceBlock",
};
</script>

<style lang="scss" scoped>

.bg-keys {
  @apply flex flex-col justify-center items-center w-full h-full bg-no-repeat;
  background-image: url(/home/img/ignition-key.webp); 
  background-position: left -90px top 200px; 
  background-size: 430px 529px;
  @media (min-width: 1024px) { 
    background-position: left -90px top 5px; 
  }
  @media (min-width: 1680px) { 
    background-size: 545px 670px;
  }
}

.circles-container {
  width: 1000px;
  @media (min-width: 1400px) { 
    width: 1160px;
  }
  @media (min-width: 1680px) { 
    width: 1460px;
  }
}
.circle {
  @apply flex flex-col 3xl:justify-center justify-start opacity-95 3xl:pt-0 pt-12 pl-24 pr-16 3xl:px-28 text-white;
  border-radius: 50%;
  width: 350px;
  height: 350px;
  @media (min-width: 1440px) { 
    width: 400px;
    height: 400px;
  }
  @media (min-width: 1680px) { 
    width: 504px;
    height: 504px;
  }
}

.circle-title {
  @apply 3xl:mb-10 mb-4 2xl:text-2xl text-xl 3xl:text-3xl font-semibold uppercase;
} 
.circle-text {
  @apply 2xl:text-lg 3xl:text-xl text-base leading-snug;
}

.item {
  @apply flex flex-col items-center justify-center mb-4;
}
.text {
  @apply flex flex-col items-center max-w-sm;
}
.title {
  @apply text-picton-blue-900 max-w-xs 3xl:max-w-max px-6 xl:text-2xl 3xl:text-3xl text-2xl text-center font-extrabold uppercase my-4 xl:my-3 2xl:my-6;
}
.icon {
  @apply xl:w-24 w-24 2xl:w-36 text-picton-blue-900;
}
.description {
  @apply 2xl:leading-snug 3xl:px-6 mb-6;
}

</style>