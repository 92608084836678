<template>
  <!-- Контейнер для Крутых технологий -->
  <div 
    class=""
    :class="[
      $screen.landscape 
        ? 'flex flex-col items-center justify-center h-full mx-auto' 
        : 'pt-6'
    ]"
  >
    <div 
      class="flex"
      :class="[
        $screen.landscape 
          ? '3xl:mb-24 mx-auto mb-12' 
          : 'flex-col py-12 px-12'
      ]"
    >
      <h2 
        class=" text-blue-dark font-bold leading-none uppercase"
        :class="[
          $screen.landscape 
            ? 'h2-title-bold z-10' 
            : 'h2-title-portrait flex justify-center flex-wrap mb-6'
        ]"
      >
        <span class="text-picton-blue-500" v-if="$i18n.locale === 'uk'">Круті&nbsp;</span>
        <span class="text-picton-blue-500" v-if="$i18n.locale === 'ru'">Крутые&nbsp;</span>
        <br v-if="$screen.landscape">
        <span v-if="$i18n.locale === 'uk'">технології</span>
        <span v-if="$i18n.locale === 'ru'">технологии</span>
      </h2>
      <div 
        class="land:pl-24 flex flex-col justify-end max-w-xl leading-snug text-gray-500"
        :class="[
          $screen.landscape 
            ? 'text-xl z-10' 
            : 'text-lg text-center mx-auto'
        ]"
      >
        <span v-if="$i18n.locale === 'uk'">
          Студія «Зіна дизайн» створює сайти на&nbsp;технологіях найбільш просунутих
          <nobr>Hi-Tech</nobr>&nbsp;компаній<span class="2xl:inline md:hidden inline"> та&nbsp;організацій</span> у&nbsp;світі.
        </span>
        <span v-if="$i18n.locale === 'ru'">
          Студия «Зина дизайн» создает сайты на&nbsp;технологиях наиболее продвинутых 
          <nobr>Hi-Tech</nobr>&nbsp;компаний<span class="2xl:inline md:hidden inline"> и&nbsp;организаций</span> в&nbsp;мире.
        </span>
      </div>
    </div>
    <!-- Текст -->
    <div 
      class="flex w-full"
      :class="[
        $screen.landscape 
          ? 'mb-24' 
          : 'justify-center'
      ]"
    >
      <!-- Left Col -->
      <div v-if="$screen.landscape" class="w-1/3"></div>
      <!-- Text Col -->
      <div 
        class=" flex flex-col items-start justify-start"
        :class="[
          $screen.landscape 
            ? '3xl:max-w-5xl 3xl:ml-24 max-w-4xl'
            : 'w-full'
        ]"
      >
        <div 
          class="mb-8 leading-snug"
          :class="[
            $screen.landscape 
              ? 'font-ptsans xl:text-2xl 3xl:text-3xl pr-16' 
              : 'description-main'
          ]"
        >
          <p v-if="$i18n.locale === 'uk'">
            Ваш сайт буде працювати на такій саме платформі, на якій працюють сайти Tesla,&nbsp;NASA, AMD, Гарварда, Оксфорда, Кембріджа, офіційні сайти США, Європейського Союзу, Великобританії та&nbsp;Німеччини. 
            <a class="hover:underline whitespace-nowrap text-zina-purple text-xl uppercase" href="/uk/drupal/sites/">Ще 365 прикладів</a>
          </p>
          <p v-if="$i18n.locale === 'ru'">
            Ваш сайт будет работать на той&nbsp;же платформе, на которой работают сайты Tesla,&nbsp;NASA, AMD, Гарварда, Оксфорда, Кембриджа, официальные сайты США, Европейского Союза, Великобритании и&nbsp;Германии. 
            <a class="hover:underline whitespace-nowrap text-zina-purple text-xl uppercase" href="/ru/drupal/sites/">Еще 365 примеров</a>
          </p>
        </div>
        <div class="sm:flex-row flex flex-col w-full">
          <div 
            v-if="$screen.portrait"
            class="musk-wrapper-mobile sm:order-none min-w-max relative flex flex-col justify-end flex-grow order-1 overflow-hidden"
          >
            <!-- Фото Маска - mobile -->
            <picture v-if="$screen.portrait">
              <source
                media="(min-width: 800px)"
                srcset="/home/img/elon-musk-transparent-450.webp"
              />
              <img
                src="/home/img/elon-musk-transparent-320.webp"
                :alt="
                  $i18n.locale === 'uk'
                    ? 'Ілон Маск використовує Drupal'
                    : 'Илон Маск использует Drupal'
                "
                :title="
                  $i18n.locale === 'uk'
                    ? 'Ілон Маск використовує Drupal, як й Студія «Зіна дизайн».'
                    : 'Илон Маск использует Drupal, как и Студия «Зина дизайн».'
                "
                class=""
              />
            </picture>
            <!-- Подложка под фото -->
            <div v-if="$screen.portrait" class="circle bg-zina-purple absolute rounded-full"></div>
          </div>
          <div class="flex flex-col items-start justify-end mr-4">
            <div 
              class="flex"
              :class="[
                $screen.landscape 
                  ? 'flex-row space-x-12 mb-8' 
                  : 'flex-col justify-center max-w-3xl sm:ml-12 mx-auto'
              ]"
            >
              <icon-base
                width="135"
                height="39"
                viewBox="0 0 134.9 38.5"
                class="h-auto"
                :class="[
                  $screen.landscape 
                    ? '2xl:w-40 w-28' 
                    : 'w-40 my-4'
                ]"
                style="color: #2ba9e0"
                :title="
                  $i18n.locale === 'uk'
                    ? 'Логотип CMS Drupal'
                    : 'Логотип CMS Drupal'
                "
                :desc="
                  $i18n.locale === 'uk'
                    ? 'Drupal – потужна система управління сайтами'
                    : 'Drupal — мощная система управления сайтами'
                "
              ><icon-drupal /></icon-base>
              <icon-base
                width="165"
                height="34"
                viewBox="0 0 165 34"
                class="h-auto"
                :class="[
                  $screen.landscape 
                    ? '2xl:w-64 w-44' 
                    : 'w-64 my-4'
                ]"
                style="color: #2ba9e0"
                :title="
                  $i18n.locale === 'uk'
                    ? 'Логотип фреймворку Yii'
                    : 'Логотип фреймворка Yii'
                "
                :desc="
                  $i18n.locale === 'uk'
                    ? 'Yii — фреймворк для складних сайтів та довгострокової підтримки'
                    : 'Yii — фреймворк для сложных сайтов и долгосрочной поддержки'
                "
              ><icon-yii /></icon-base>
              <icon-base
                width="176"
                height="51"
                viewBox="0 0 176 51"
                class="h-auto"
                :class="[
                  $screen.landscape 
                    ? '2xl:w-48 w-36' 
                    : 'w-48 my-4'
                ]"
                style="color: #FF2D20"
                :title="
                  $i18n.locale === 'uk'
                    ? 'Логотип фреймворку Laravel'
                    : 'Логотип фреймворка Laravel'
                "
                :desc="
                  $i18n.locale === 'uk'
                    ? 'Laravel — система для створення сайтів у сучасному стилі'
                    : 'Laravel — система для создания сайтов в современном стиле'
                "
              ><icon-laravel /></icon-base>
              <icon-base
                width="105"
                height="37"
                viewBox="0 0 104.8 36.9"
                class="h-auto"
                :class="[
                  $screen.landscape 
                    ? '2xl:w-32 w-28' 
                    : 'w-32 my-4'
                ]"
                :title="'Логотип Vue.js'"
                :desc="
                  $i18n.locale === 'uk'
                    ? 'Vue.js — для максимальної швидкості фронтенду'
                    : 'Vue.js — для максимальной скорости фронтенда'
                "
              ><icon-vue /></icon-base>
            </div>
            <div 
              class="leading-snug text-gray-500"
              :class="[
                $screen.landscape 
                  ? 'w-full text-left text-xl' 
                  : 'text-lg mt-6 mb-6 ml-12'
              ]"
            >
              <p v-if="$i18n.locale === 'uk'">Ілон Маск та Зіна&nbsp;дизайн використовують CMS&nbsp;Drupal</p>
              <p v-if="$i18n.locale === 'ru'">Илон Маск и Зина&nbsp;дизайн используют CMS&nbsp;Drupal</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Фото Маска -->
    <picture v-if="$screen.landscape">
      <source
        media="(min-width: 1680px)"
        srcset="/home/img/elon-musk-transparent.webp"
      />
      <source
        media="(min-width: 412px)"
        srcset="/home/img/elon-musk-transparent-450.webp"
      />
      <img
        src="/home/img/elon-musk-transparent.webp"
        :alt="
          $i18n.locale === 'uk'
            ? 'Ілон Маск використовує Drupal'
            : 'Илон Маск использует Drupal'
        "
        :title="
          $i18n.locale === 'uk'
            ? 'Ілон Маск використовує Drupal, як й Студія «Зіна дизайн».'
            : 'Илон Маск использует Drupal, как и Студия «Зина дизайн».'
        "
        class="absolute bottom-0 left-0"
      />
    </picture>
    <!-- Подложка под фото -->
    <div v-if="$screen.landscape" class="circle bg-zina-purple absolute rounded-full"></div>
  </div>
  
</template>

<script>
import { defineAsyncComponent } from "vue";
const IconBase = defineAsyncComponent(() => import("@/components/IconBase"));
const IconDrupal = defineAsyncComponent(() => import("@/components/icons/IconDrupal"));
const IconYii = defineAsyncComponent(() => import("@/components/icons/IconYii"));
const IconLaravel = defineAsyncComponent(() => import("@/components/icons/IconLaravel"));
const IconVue = defineAsyncComponent(() => import("@/components/icons/IconVue"));
// import IconBase from '@/components/IconBase.vue'
// import IconDrupal from '@/components/icons/IconDrupal.vue'
// import IconYii from '@/components/icons/IconYii.vue'
// import IconLaravel from '@/components/icons/IconLaravel.vue'
// import IconVue from '@/components/icons/IconVue.vue'
export default {
  name: "TechnologyBlock",
  components: {
    IconBase,
    IconDrupal,
    IconYii,
    IconLaravel,
    IconVue,
  },
  data() {
    return {
      isButtonActive: false,
    };
  },
  methods: {
    toggle() {
      this.isButtonActive = ! this.isButtonActive;
    },
  },
};

</script>

<style lang="scss" scoped>
  .mask-wrapper {
    width: 600px;
    height: 600px;
  }
  .musk-wrapper-mobile {
    min-height: 365px;
    @media (min-width: 600px) {
      min-height: 480px;
    }
  }
  .circle {
    z-index: -1;
    background: linear-gradient(180deg, rgba(137,105,203,1) 0%, rgba(118,221,254,1) 100%);
    width: 490px;
    height: 490px;
    left: -210px; 
    bottom: -130px;
    @media (min-width: 800px) {
      width: 600px;
      height: 600px;
    }
    @media (min-width: 1680px) { 
      width: 770px;
      height: 770px;
    }
  }
</style>