<template>
  <div class="popup" v-if="show" @click.stop="hidePopup">
    <div class="popup__content" @click.stop>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "custom-popup",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    hidePopup() {
      this.$emit("update:show", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.popup {
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: flex;
  z-index: 9999999;
}
.popup__content {
  margin: auto;
  background: white;
  border-radius: 12px;
  min-height: 50px;
  min-width: 300px;
  padding: 20px;
}
</style>