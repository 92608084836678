import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate"
import { modalModule } from "./modalModule"
import { selectModule } from "./selectModule"
import { sectionModule } from "./sectionModule"

// Через стор можно управлять состояниями елементов и логикой
export default createStore({
  plugins: [createPersistedState()],
  modules: {
    modal: modalModule,
    select: selectModule,
    section: sectionModule
  }
})
