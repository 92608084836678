import CustomButton from "@/components/UI/CustomButton.vue";
import CustomPopup from "@/components/UI/CustomPopup.vue";
import CustomAnchorButton from "@/components/UI/CustomAnchorButton.vue";
import NextSectionButton from "@/components/UI/NextSectionButton.vue";
import ButtonNextSection from "@/components/UI/ButtonNextSection.vue";
import ButtonNextSectionYellow from "@/components/UI/ButtonNextSectionYellow.vue";

export default [
    CustomButton,
    CustomPopup,
    CustomAnchorButton,
    NextSectionButton,
    ButtonNextSection,
    ButtonNextSectionYellow,
]