<template>
  <!-- Обертка — ради класса 'relative', чтобы относительно этого блока отыгрывать все изменения размера -->
  <div
    class="relative flex items-center justify-center"
    :style="['width: ' + buttonWidth, { 'z-index: 50': isButtonActive }]"
  >
    <!-- 
      Кнопка — сжимается по горизонтали в кружок, т.е. до ширины, равной высоте. 
      Ширину кнопки в обоих состояниях нужно указывать принудительно, иначе не сработает анимация.
      Анимация начинается с задержкой для того, чтобы успела отыграть анимация исчезновения надписи.
    -->
    <button
      :id="'background_' + id"
      class="hover:shadow-xl pb-0.5 rounded-full"
      :style="[
        'height: ' + buttonHeight,
        'transition: background-color 0s',
        isButtonActive
          ? 'transition: width 300ms ease 200ms, background-color 300ms ease 200ms; width: ' + buttonHeight
          : 'transition: width 300ms ease 900ms; width: ' + buttonWidth,
        isButtonHover
          ? 'background-color: ' + buttonColorActive
          : 'background-color: ' + buttonColorStatic,
      ]"
      @mouseover="isButtonHover = true"
      @mouseout="isButtonHover = false"
      @click="Submit()"
    >
      <!-- 
        Надпись на кнопке — исчезает чуть раньше, чем сожмется кнопка.
        Когда кнопка активна, исчезает сразу, потому что сразу начинается анимация сжатия кнопки.
        В обратном направлении, после небольшой задержки для анимации разжатия кнопки, 
        проходит через два последовательных состояния: сначала быстро появляется прозрачной, 
        потом чуть медленнее убирается прозрачность. 
      -->
      <span
        class="hover:text-white tracking-wide text-red-100 uppercase"
        :style="[
          'font-size: ' + buttonTextSize,
          'max-height: ' + buttonHeight, // ограничение, чтобы текст не выходил за пределы, если сжатие кнопки происходит слишком быстро
          isButtonActive
            ? 'visibility: hidden;  opacity: 0; transition: opacity 200ms ease 0ms, visibility 200ms ease 0ms'
            : 'visibility: visible; opacity: 1; transition: opacity 500ms ease 1200ms, visibility 1200ms step-end',
          isButtonHover
            ? 'color: ' + buttonTextColorHover
            : 'color: ' + buttonTextColor,
        ]"
      >
        <!-- Сюда вставляется текст на кнопке -->
        <slot></slot>
      </span>
    </button>
    <!-- 
      Кружок-подложка под кнопкой — расширяется до круга, закрывающего весь экран.
      Клик по подложке закрывает её.
    -->
    <div
      class="right-1/2 ease absolute top-0 z-50 rounded-full"
      :style="[
        'width: ' + buttonHeight,
        'height: ' + buttonHeight,
        'background-color: ' + buttonColor,
        'transform: translateX(50%)',
        isButtonActive
          ? 'visibility: visible; transition: 700ms ease 500ms; transform: scale(' +
            buttonScale +
            ');'
          : 'visibility: hidden;  transition: 700ms ease 200ms;',
      ]"
      @click="Submit()"
    ></div>
  </div>
  <!-- Модальное окно -->
  <div
    id="background"
    class="fixed inset-0 z-50 overflow-y-auto"
    :style="[
      isButtonActive
        ? 'visibility: visible; transition: visibility 0ms ease 1200ms;'
        : 'visibility: hidden; transition: visibility 0ms ease 200ms;',
    ]"
    @click="Submit()"
  >
    <!-- Кнопка закрытия -->
    <button
      @click.prevent
      class="sm:top-6 sm:right-6 top-0.5 right-2 sm:w-12 sm:h-12 absolute z-50 w-8 h-8 text-gray-500 rounded-full hover:bg-red-800"
      :style="[
        isButtonActive
          ? 'opacity: 1; transition: opacity 1000ms ease 1200ms;'
          : 'opacity: 0;',
      ]"
    >
      <span class="block sm:text-white sm:h-1 h-0.5 mx-auto my-1.5 bg-current rounded transform sm:w-8 w-5 rotate-45 sm:translate-y-1 translate-y-1"></span>
      <span class="block sm:text-white sm:h-1 h-0.5 mx-auto my-1.5 bg-current rounded transform sm:w-8 w-5 sm:-translate-y-1.5 -translate-y-1 -rotate-45"></span>
    </button>

    <!-- Контент -->
    <div 
      v-if="isButtonActive"
      class="flex items-center justify-center min-h-screen"
    >
      <div
        class=" md:w-max sm:w-screen sm:rounded-2xl sm:my-8 sm:mx-16 lg:rounded-3xl overflow-hidden align-bottom bg-white shadow-xl"
        :style="[
          isButtonActive
            ? 'opacity: 1; transition: opacity 300ms ease-in 1200ms;'
            : 'opacity: 0; transition: opacity 200ms ease 0ms;',
        ]"
        @click.stop
      >
        <!-- Форма -->
        <order-modal />
      </div>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
import { mapMutations } from "vuex";

const OrderModal = defineAsyncComponent(() =>
  import("@/components/UI/OrderModal")
);
export default {
  name: "button-morph-modal",

  components: {
    OrderModal,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
    buttonWidth: {
      type: String,
      default: "10rem",
    },
    buttonHeight: {
      type: String,
      default: "2rem",
    },
    buttonTextSize: {
      type: String,
      default: "5rem",
    },
    buttonTextColor: {
      type: String,
      default: "#FEE2E2",
    },
    buttonTextColorHover: {
      type: String,
      default: "#FFF",
    },
    buttonColorStatic: {
      type: String,
      default: "#B91C1C",
    },
    buttonColor: {
      type: String,
      default: "#B91C1C",
    },
    buttonColorActive: {
      type: String,
      default: "#DC2626",
    },
    buttonScale: {
      type: Number,
      default: 100,
    },
  },

  data() {
    return {
      isButtonHover: false,
      isButtonActive: false,
    };
  },

  watch: {
    isButtonHover(val) {
      let background = document.getElementById("background_" + this.id);
      if (val === true) {
        background.style.backgroundColor = this.buttonColor;
      } else {
        background.style.backgroundColor = this.buttonColorActive;
      }
    },
  },

  methods: {
    ...mapMutations({
      setModalVisible: "modal/setModalVisible",
    }),
    Submit() {
      this.isButtonActive = !this.isButtonActive;
      this.setModalVisible(this.isButtonActive);
      this.$emit("modal", this.isButtonActive);
    },
    Esc(event) {
      if (event.code == "Escape") {
        this.setModalVisible(false);
      }
    },
  },

  mounted() {
    document.addEventListener("keydown", this.Esc);
  },

  beforeUnmount() {
    window.removeEventListener("keydown", this.Esc);
  },
};
</script>
