<template>
  <div 
    class="flex flex-col items-center justify-between h-full"
    :class="[
      $screen.landscape 
        ? '' 
        : 'pt-24'
    ]"
  >

    <h2 
      class="text-blue-dark font-bold leading-none"
      :class="[
        $screen.landscape 
          ? 'h2-title-bold mt-20 mb-12' 
          : 'h2-title-portrait mb-12'
      ]"
    >
      <span v-if="$i18n.locale === 'uk'">
        <span class="text-picton-blue-500 uppercase">Офіс</span> у центрі Дніпра
      </span>
      <span v-if="$i18n.locale === 'ru'">
        <span class="text-picton-blue-500 uppercase">Офис</span> в центре Днепра
      </span>
    </h2>
    <p 
      class="sm:text-3xl text-2xl text-center"
      :class="[
        $screen.landscape 
          ? 'mb-10' 
          : 'mb-16'
      ]"
    >
      <icon-base
        width="17"
        height="24"
        viewBox="0 0 17.4 24"
        class="text-zina-teal h-6 mb-1 mr-2"
        :title="
          $i18n.locale === 'uk'
            ? 'Адреса офісу веб-студії у Дніпрі'
            : 'Адрес офиса веб-студии в Днепре'
        "
      ><icon-map /></icon-base>
      <span v-if="$i18n.locale === 'uk'">
        Дніпро, Короленка&nbsp;1
      </span>
      <span v-if="$i18n.locale === 'ru'">
        Днепр, Короленко&nbsp;1
      </span>
    </p>
    <iframe 
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2645.4065515369316!2d35.02874231566057!3d48.467914979251084!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x85f7d5498e3cb288!2z0JfQuNC90LAg0LTQuNC30LDQudC9!5e0!3m2!1sru!2s!4v1491143608022" 
      frameborder="0" 
      class="w-full"
      :class="[
        $screen.landscape 
          ? '3xl:mb-28 flex-grow ' 
          : 'h-96'
      ]"
      :style="[
        $screen.portrait 
          ? 'height: 500px;' 
          : ''
      ]"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
import IconBase from '@/components/IconBase.vue'
import IconMap from '@/components/icons/IconMap.vue'
export default {
  name: "OfficeBlock",
  components: {
    IconBase,
    IconMap,
  }};

</script>

<style scoped>
</style>