<template>
  <div 
    class="flex flex-col justify-center w-full h-full"
    :class="[
      $screen.landscape 
        ? '' 
        : 'pt-24'
    ]"
  >
    <div 
      class="container-gradient h-2/3 absolute flex justify-end w-full"
      :class="[
        $screen.landscape 
          ? 'top-14' 
          : 'top-0'
      ]"
    ></div>
    <h2 
      class=" font-roboto font-light tracking-tight text-center text-white"
      :class="[
        $screen.landscape 
          ? 'h2-title-bold 3xl:mt-20 3xl:mb-8 z-10 mt-8 mb-4 ' 
          : 'h2-title-portrait mb-12'
      ]"
    >
      <span v-if="$i18n.locale === 'uk'"><span class="uppercase">Відгуки</span> <span class="md:text-5xl font-extralight block text-4xl leading-none">справжніх людей</span></span>
      <span v-if="$i18n.locale === 'ru'"><span class="uppercase">Отзывы</span> <span class="md:text-5xl font-extralight block text-4xl leading-none">настоящих людей</span></span>
    </h2>
    <div 
      class="relative mx-auto"
      :class="[
        $screen.landscape 
          ? 'max-w-6xl' 
          : 'max-w-2xl w-4/5 pb-8 mb-24'
      ]"
    >
      <div 
        class="h-4/5 absolute bottom-0 w-full bg-white"
        style="box-shadow: 0px 0px 80px #BAC3D4;"
      ></div>
      <Splide
        :options="{
          type: 'loop',
          focus: 'center',
          autoplay: false,
          pauseOnHover: true,
          // flickMaxPages: 1,
          // updateOnMove: true,
          pagination: true,
          lazyLoad: 'nearby',
          preloadPages: '1',
          perMove: 1,
          perPage: 1,
          // autoWidth: true,
          // width: '1200px',
          // breakpoints: {
            //600: {
            //  width: '100vw',
            //},
            //1850: {
            //  width: '80vw',
            //},
          //},
          speed: '1000',
          //gap: '1rem',
          // padding:'29vw',
          // padding:'562px',
          //padding:'390px',
        }"
      >
        <SplideSlide v-for="(slide, index) in slides" :key="index">
          <div class="draggable land:px-16 relative flex flex-col items-center justify-center pl-6 pr-4">
            <img
              class="xl:mb-4 3xl:w-max 3xl:mb-8 xl:w-36 w-min mb-8 rounded-full"
              :data-splide-lazy="'/home/img/feedback/'+slide.image"
              :alt="slide.image_alt[$i18n.locale]"
            />
            <div class="feedback-description text-center">
              <p class="feedback-description__title" v-html="slide.title[$i18n.locale]" />
              <p class="feedback-description__subheader">{{ slide.subheader[$i18n.locale] }}</p>
              <p class="feedback-description__text">{{ slide.text[$i18n.locale] }}</p>
              <p class="feedback-description__more">
                <a 
                  class="feedback-link" 
                  :href="
                    $i18n.locale === 'uk' 
                    ? `/uk/feedback/${slide.feedback_link}`
                    : `/ru/feedback/${slide.feedback_link}`
                  "
                >
                  {{ $t("More") }}
                </a>
              </p>
            </div>
          </div>
        </SplideSlide>
        <template #before-track>
          <div class="splide__arrows text-white">
            <button class="splide__arrow splide__arrow--prev">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 60" width="20" height="60">
                <path fill="currentColor" d="M3.7 60 0 58.1 15.3 30 0 1.9 3.7 0 20 30 3.7 60z"/>
              </svg>
            </button>
            <button class="splide__arrow splide__arrow--next">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 60" width="20" height="60">
                <path fill="currentColor" d="M3.7 60 0 58.1 15.3 30 0 1.9 3.7 0 20 30 3.7 60z"/>
              </svg>
            </button>
          </div>
        </template>
      </Splide>
      <!-- <div class="h-4/5 bg-gradient-to-r from-transparent to-white absolute bottom-0 right-0 w-24"></div>
      <div class="h-4/5 bg-gradient-to-l from-transparent to-white absolute bottom-0 left-0 w-24"></div> -->
    </div>
  <!-- </div> -->
    <div class="h-1/6"></div>
  </div>

<!-- arrow left -->
<!-- 
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 60">
  <path fill="#fff" d="M16.3 0 20 1.9 4.7 30 20 58.1 16.3 60 0 30 16.3 0z"/>
</svg>
-->

<!-- arrow right -->
<!-- 
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 60">
  <path fill="#fff" d="M3.7 60 0 58.1 15.3 30 0 1.9 3.7 0 20 30 3.7 60z"/>
</svg>
-->

</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { defineComponent } from "vue";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

const data = [
  {
    feedback_link: "#denis-lapshin",
    image: "denis-lapshin.webp",
    title: {
      uk: "Денис Лапшин<br />Компанія «Велес-Прінт»",
      ru: "Денис Лапшин<br />Компания «Велес-Принт»",
    },
    subheader: {
      uk: "Менеджер, виробництво упаковки",
      ru: "Менеджер, производство упаковки",
    },
    text: {
      uk: "Через півроку з початку робіт над сайтом, ми по більшості запитів знаходимося на першій сторінці пошуку Google, щодня надходять дзвінки від потенційних замовників, які знайшли нас у пошуку.",
      ru: "Cпустя полгода с начала работ над сайтом, мы по большинству запросов находимся на первой странице поиска Google, ежедневно поступают звонки от потенциальных заказчиков, которые нашли нас в поиске.",
    },
    image_alt: {
      uk: "Відгуки клієнтів студії «Зіна дизайн» — Денис Лапшин.",
      ru: "Отзывы клиентов студии «Зина дизайн» — Денис Лапшин",
    },
  },
  {
    feedback_link: "#dmytro-shapovalov",
    image: "dmytro-shapovalov.webp",
    title: {
      uk: "Дмитро Шаповалов",
      ru: "Дмитрий Шаповалов",
    },
    subheader: {
      uk: "Власник компанії, інтернет-провайдер",
      ru: "Владелец компании, интернет-провайдер",
    },
    image_alt: {
      uk: "Відгуки клієнтів студії «Зіна дизайн» — Дмитро Шаповалов",
      ru: "Отзывы клиентов студии «Зина дизайн» — Дмитрий Шаповалов",
    },
    text: {
      uk: "Завдання студія виконала на «відмінно». Наталя розуміла побажання з напівлітери, дуже витончено, самобутньо та лаконічно втілювала думки. Отже: безумовно рекомендую, вам сподобаються і процес, і результат.",
      ru: "Задачу студия выполнила на «отлично». Наталья понимала пожелания с полубуквы, очень изящно, самобытно и лаконично воплощала мысли. Итого: безусловно рекомендую, вам понравятся и процесс, и результат.",
    }
  },
  {
    feedback_link: "#platon-borodatyi",
    image: "platon-borodatyi.webp",
    image_alt: {
      uk: "Відгуки клієнтів студії «Зіна дизайн» — Платон Бородатий",
      ru: "Отзывы клиентов студии «Зина дизайн» — Платон Бородатый",
    },
    title: {
      uk: "Платон Бородатий",
      ru: "Платон Бородатый",
    },
    subheader: {
      uk: "Маркетолог, IT",
      ru: "Маркетолог, IT",
    },
    text: {
      uk: "Працював із хлопцями за рекомендацією, робили імплементацію дизайну на CMS. Все зробили швидко, якісно, одним словом на рівні. Після розгортання сайту зробили багфіксінг, максимально швидкий час відповіді. Рекомендую.",
      ru: "Работал с ребятами по рекомендации, делали имплементацию дизайна на CMS. Все сделали быстро, качественно, одним словом на уровне. После развертывания сайта, сделали багфиксинг, максимально быстрое время ответа. Рекомендую.",
    }
  },
  {
    feedback_link: "#olga-titarenko",
    image: "olga-titarenko.webp",
    title: {
      uk: "Ольга Титаренко",
      ru: "Ольга Титаренко",
    },
    image_alt: {
      uk: "Відгуки клієнтів студії «Зіна дизайн» — Ольга Титаренко",
      ru: "Отзывы клиентов студии «Зина дизайн» — Ольга Титаренко",
    },
    subheader: {
      uk: "Підприємець, дитячий розвиток",
      ru: "Предприниматель, детское развитие",
    },
    text: {
      uk: "Креативні дизайнери, спритні програмісти — все, що потрібне для створення сайту, я отримала у «Зіни дизайн»! Хлопці, дякую за приємну співпрацю, з Вами дуже комфортно працювати.",
      ru: "Креативные дизайнеры, шустрые программисты — все, что нужно для создания сайта, я получила у «Зины дизайн»! Ребята, спасибочки за приятное сотрудничество, с Вами очень комфортно работать.",
    }
  },
  {
    image: "aris-pack.webp",
    feedback_link: "#aris-pack",
    image_alt: {
      uk: "Відгуки клієнтів студії «Зіна дизайн» — Компанія «Аріс Пак»",
      ru: "Отзывы клиентов студии «Зина дизайн» — Компания «Арис Пак»",
    },
    title: {
      uk: "Компанія «Аріс Пак»",
      ru: "Компания «Арис Пак»",
    },
    subheader: {
      uk: "Виробництво упаковки",
      ru: "Производство упаковки",
    },
    text: {
      uk: "Студія «Зіна дизайн» займається просуванням сайту нашої компанії протягом двох років. За цей час сайт, який знаходився під санкціями пошукових систем, зайняв перші позиції у Google.",
      ru: "Студия «Зина дизайн» занимается продвижением сайта нашей компании на протяжении двух лет. За это время сайт, который находился под санкциями поисковых систем, занял первые позиции в Google.",
    }
  },
  {
    image: "alex-fisun.webp",
    feedback_link: "#alex-fisun",
    image_alt: {
      uk: "Отзывы клиентов студии «Зина дизайн» — Алексей Фисун",
      ru: "Відгуки клієнтів студії «Зіна дизайн» — Олексій Фісун",
    },
    title: {
      uk: "Олексій Фісун",
      ru: "Алексей Фисун",
    },
    subheader: {
      uk: "Маркетолог, IT",
      ru: "Маркетолог, IT",
    },
    text: {
      uk: "Працюю з хлопцями з 2011 року. Такого рівня розуміння клієнта та якість роботи ви не знайдете на теренах СНД. А за такі гроші так вже точно.",
      ru: "Работаю с ребятами с 2011 года. Такого уровня понимание клиента и качество работы вы не найдете на просторах СНГ. А за такие деньги так уж точно.",
    }
  },
  {
    image: "konstantin-eremin.webp",
    feedback_link: "#konstantin-eremin",
    image_alt: {
      uk: "Відгуки клієнтів студії «Зіна дизайн» — Костянтин Єрьомін",
      ru: "Отзывы клиентов студии «Зина дизайн» — Константин Ерёмин",
    },
    title: {
      uk: "Костянтин Єрьомін",
      ru: "Константин Ерёмин",
    },
    subheader: {
      uk: "Керівник радіостанції, підприємець",
      ru: "Руководитель радиостанции, предприниматель",
    },
    text: {
      uk: "Особливо хочу відзначити талант арт-директора студії Наталії, завдяки якій кожен сайт виглядає не шаблонно і сучасно, і чітку роботу техпідтримки — хлопці відповідають на всі питання оперативно і по сутності.",
      ru: "Особо хочу отметить талант арт-директора студии Натальи, благодаря которой каждый сайт выглядит не шаблонно и современно, и четкую работу техподдержки – ребята отвечают на все вопросы оперативно и по сути.",
    }
  },
];

export default defineComponent({
  name: "FeedBackBlock",
  setup() {
    const slides = data;
    return { slides };
  },
  components: {
    Splide,
    SplideSlide,
  },
  data() {
    return {
      visible: false,
      index: 0, // default: 0
    };
  },
  methods: {
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },
});
</script>

<style lang="scss">

  // Splide - общие стили

  /* Splide Arrows */

  .splide__arrow {
    @apply bg-transparent;
  }
  .splide__arrow svg {
    width: 20px;
    height: 60px;
  }

  /* Splide Pagination */

  .splide__pagination {
    @apply bottom-4 3xl:-bottom-10;
  }
  .splide__pagination__page {
    @apply w-3 h-3 mx-1.5 bg-gray-300;
    transition: 0.4s ease all;
  }
  .splide__pagination__page.is-active {
    @apply ring-2 bg-gray-400 mx-3 ring-gray-200;
    transform: scale(1.4);
  }

  /* Курсор для обозначения возможности таскать мышкой */
  .draggable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }
  }

</style>

<style lang="scss" scoped>

.feedback-description__title {
  @apply font-bold text-xl md:text-3xl xl:text-2xl 3xl:text-3xl mb-2 md:mb-6 xl:mb-3;
  // @apply border border-blue-500;
}
.feedback-description__subheader {
  @apply text-sm 3xl:text-xl mb-3 md:mb-8 xl:mb-3 3xl:mb-6 text-gray-600 px-8;
  // @apply border border-pink-500;
}
.feedback-description__text {
  @apply text-left md:text-lg xl:text-base 3xl:text-xl mb-4 3xl:mb-16 text-black;
  // @apply border border-purple-500;
}
.feedback-description__more {
  @apply mb-12;
  // @apply border border-indigo-500;
}
.feedback-link {
  @apply text-sm md:text-base xl:text-sm 3xl:text-xl text-zina-purple underline pb-1 mb-2 italic hover:text-picton-blue-500;
}

// Splide - стили для текущего слайдера

.splide__slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.splide__arrow.splide__arrow--prev {
  @apply -left-9;
}
.splide__arrow.splide__arrow--next {
  @apply -right-9;
}

</style>


