module.exports = {
  // mode: 'jit',
  purge: [
    './public/**/*.html',
    './src/**/*.{js,vue}',
  ],
  darkMode: false, // or 'media' or 'class'
  theme: {
    extend: {
      fontFamily: {
        'roboto': ['Roboto', 'sans-serif'],
        'ptsans': ['PT\\ Sans', 'sans-serif'],
      },
      colors: {
        'zina-red': {
          DEFAULT: '#ED1D24',
          '50': '#FBC7C9',
          '100': '#F9B4B6',
          '200': '#F68E92',
          '300': '#F3696D',
          '400': '#F04349',
          '500': '#ED1D24',
          '600': '#C20F16',
          '700': '#8E0B10',
          '800': '#5A070A',
          '900': '#270304'
        },
        'picton-blue': {
          '50': '#f2fbfe', 
          '100': '#e6f7fd', 
          '200': '#bfebfb', 
          '300': '#95bcdb', // #99def9
          '400': '#4dc6f4', 
          '500': '#00adef', // blue light
          '600': '#009cd7', 
          '700': '#3480ba', // #0082b3
          '800': '#00688f', 
          '900': '#0071b5',
        },
        'zina-teal': '#007EC2',
        'zina-purple': '#8969CB',
        'zina-yellow': '#FFF567',
        'blue-dark': '#007EC2',
        'green-bright': '#5BAF0E',
        'zina-gray-light': '#F8F8F8',
        'zina-gray-dark': '#6B7280',
        'viber': '#6E64C3',
        'whatsapp': '#64B161',
        'telegram': '#039BE5',
        'skype': '#2196F3',
      }
    },
    container: {
      center: true,
      padding: '1rem',
      screens: {
        sm: "100%",
        md: "100%",
        lg: "1024px",
        xl: "1280px",
        '2xl': "1536px",
        '3xl': "1536px",
        '4xl': "1921px",
      }
    },
    screens: {
      // Tablets
      sm: '600px', // customized, Tailwind default - 640px
      md: '768px', // iPad
      // Desktops
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
      '3xl': '1850px', // custom: 1920px - left panel (~70px)
      '4xl': '1921px', 
      // Additional
      land: {'raw': '(orientation: landscape)'}, // => @media (orientation: landscape) { ... }
      print: {'raw': 'print'}, // => @media print { ... }
    },
    transitionProperty: {
      'height': 'height',
      'spacing': 'margin, padding',
     }
  },
  variants: {
    extend: {
      borderColor: ['hover'],
      borderStyle: ['hover'],
      borderRadius: ['hover'],
      margin: ['hover'],
      padding: ['hover'],
      ringWidth: ['hover', 'active'],
    }
  }
}
