<template>
  <div 
    class="price-container justify-evenly flex flex-col items-center h-full bg-no-repeat"
  >
    <div 
      class="flex w-full"
      :class="[
        $screen.landscape 
          ? 'items-end' 
          : 'flex-col pt-24'
      ]"
    >
      <div 
        class=""
        :class="[
          $screen.landscape 
            ? 'w-1/2 text-right' 
            : 'w-full text-center'
        ]"
      >
        <h2 
          class="text-blue-dark font-bold leading-none uppercase"
          :class="[
            $screen.landscape 
              ? 'h2-title-bold z-10' 
              : 'h2-title-portrait flex justify-center flex-wrap mb-12'
          ]"
        >
          <span v-if="$i18n.locale === 'uk'">
            <span class="text-picton-blue-500">Справедлива</span> ціна
          </span>
          <span v-if="$i18n.locale === 'ru'">
            <span class="text-picton-blue-500">Справедливая</span> цена
          </span>
        </h2>
      </div>
      <div 
        class="font-roboto"
        :class="[
          $screen.landscape 
            ? 'pl-14 description-big 3xl:max-w-2xl w-1/2 max-w-lg pb-1' 
            : 'description-main'
        ]"
      >
        <span v-if="$i18n.locale === 'uk'">
          Ми 
          <svg viewBox="0 0 24 16" class="inline-block w-6 2xl:w-10 mb-0.5 2xl:mb-2">
            <rect style="fill:#005BBB;" width="24" height="16"/>
            <rect y="8" style="fill:#FFD500;" width="24" height="8"/>
          </svg>
          українська компанія
          <span class="whitespace-nowrap">
            з
            <svg viewBox="0 0 12 16" class="3xl:w-6 3xl:mb-2 inline-block w-4 mb-1 ml-1">
              <path style="fill:#003087;" d="M9.6,3.5C8,3.5,7,3.9,6.1,4.4c-0.9,0.4-1.7,0.8-3,0.8c-1,0-1.7-0.2-2.5-0.6C0.5,4.6,0.3,4.5,0.1,4.4 L0,4.3v5.2C0,14.5,6,16,6,16s6-1.5,6-6.5V3.9C11.2,3.6,10.4,3.5,9.6,3.5z M10.5,9.5c0,1.5-0.8,2.8-2.3,3.8 c-0.7,0.5-1.4,0.8-2.2,1.1C4.9,14,1.5,12.7,1.5,9.5V6.7C2,6.9,2.6,7,3.1,7c1.3,0,2.1-0.4,3-0.8C7,5.7,8,5.2,9.6,5.2 c0.3,0,0.6,0,0.9,0.1L10.5,9.5z"/>
              <path style="fill:#62B5E5;" d="M10.5,0.1C10.2,0,9.9,0,9.5,0C8,0,7,0.5,6.1,0.9c-0.9,0.4-1.7,0.8-3,0.8C2.5,1.7,2,1.6,1.5,1.5 C1,1.3,0.6,1.1,0.1,0.9L0,0.9v1.8l0.1,0.1C0.6,2.9,1,3.1,1.5,3.2c0.5,0.2,1,0.2,1.6,0.2c1.3,0,2.1-0.4,3-0.8C7,2.2,8,1.8,9.5,1.8 c0.3,0,0.6,0,1,0.1C11,1.9,11.5,2,12,2.2V0.5C11.5,0.3,11,0.1,10.5,0.1z"/>
            </svg>
            Дніпра
          </span> 
          і&nbsp;тримаємо наші ціни доступними. Особливо під час війни.
        </span>
        <span v-if="$i18n.locale === 'ru'">
          Мы 
          <svg viewBox="0 0 24 16" class="inline-block w-6 2xl:w-10 mb-0.5 2xl:mb-2">
            <rect style="fill:#005BBB;" width="24" height="16"/>
            <rect y="8" style="fill:#FFD500;" width="24" height="8"/>
          </svg>
          украинская компания 
          <span class="whitespace-nowrap">
            из
            <svg viewBox="0 0 12 16" class="3xl:w-6 3xl:mb-2 inline-block w-4 mb-1 ml-1">
              <path style="fill:#003087;" d="M9.6,3.5C8,3.5,7,3.9,6.1,4.4c-0.9,0.4-1.7,0.8-3,0.8c-1,0-1.7-0.2-2.5-0.6C0.5,4.6,0.3,4.5,0.1,4.4 L0,4.3v5.2C0,14.5,6,16,6,16s6-1.5,6-6.5V3.9C11.2,3.6,10.4,3.5,9.6,3.5z M10.5,9.5c0,1.5-0.8,2.8-2.3,3.8 c-0.7,0.5-1.4,0.8-2.2,1.1C4.9,14,1.5,12.7,1.5,9.5V6.7C2,6.9,2.6,7,3.1,7c1.3,0,2.1-0.4,3-0.8C7,5.7,8,5.2,9.6,5.2 c0.3,0,0.6,0,0.9,0.1L10.5,9.5z"/>
              <path style="fill:#62B5E5;" d="M10.5,0.1C10.2,0,9.9,0,9.5,0C8,0,7,0.5,6.1,0.9c-0.9,0.4-1.7,0.8-3,0.8C2.5,1.7,2,1.6,1.5,1.5 C1,1.3,0.6,1.1,0.1,0.9L0,0.9v1.8l0.1,0.1C0.6,2.9,1,3.1,1.5,3.2c0.5,0.2,1,0.2,1.6,0.2c1.3,0,2.1-0.4,3-0.8C7,2.2,8,1.8,9.5,1.8 c0.3,0,0.6,0,1,0.1C11,1.9,11.5,2,12,2.2V0.5C11.5,0.3,11,0.1,10.5,0.1z"/>
            </svg>
            Днепра
          </span> 
          и&nbsp;держим наши цены доступными. Особенно во время войны.
        </span>
      </div>
    </div>
    <div 
      class="font-ptsans flex"
      :class="[
        $screen.landscape 
          ? '2xl:space-x-32 max-w-screen-xl mb-12 ml-32 space-x-16' 
          : 'flex-col mt-24'
      ]"
    >

      <!-- 1 колонка -->

      <div class="price-col text-zina-purple">
        <div 
          class="baloon"
          style="box-shadow: 0px 0px 80px #8969CB;"
        >
          <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 61" class="icon icon-landing"
            role="img" 
            :aria-label="
              $i18n.locale === 'uk'
                ? 'Вартість створення лендінгу'
                : 'Стоимость создания лендинга'
            "
          >
          <title v-if="$i18n.locale === 'uk'">Вартість створення лендінгу</title>
          <title v-if="$i18n.locale === 'ru'">Стоимость создания лендинга</title>
          <desc v-if="$i18n.locale === 'uk'">Односторінковий сайт, або лендінг: одна довга, яскрава сторінка, що націлена на максимум продажів.</desc>
          <desc v-if="$i18n.locale === 'ru'">Одностраничный сайт или лендинг: одна длинная, яркая страница, нацеленная на максимум продаж.</desc>
            <g fill="#cec1ea">
              <path d="M0 24.5h12v12H0v-12zM19 13.5h34v34H19v-34zM60 24.5h12v12H60v-12z" />
            </g>
          </svg> -->
          <icon-base
            width="72"
            height="61"
            viewBox="0 0 72 61"
            class="icon icon-landing"
            style="color: #cec1ea"
            :title="
              $i18n.locale === 'uk'
                ? 'Вартість створення лендінгу'
                : 'Стоимость создания лендинга'
            "
            :desc="
              $i18n.locale === 'uk'
                ? 'Односторінковий сайт, або лендінг: одна довга, яскрава сторінка, що націлена на максимум продажів.'
                : 'Одностраничный сайт или лендинг: одна длинная, яркая страница, нацеленная на максимум продаж.'
            "
          ><icon-landing /></icon-base>
          <h4 class="price-title">
            {{ $t('Landing') }}
          </h4>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 80 27" class="baloon-tile">
          <path fill="#FFF" d="M0 0s40 .3 40 27C40-1 80 0 80 0H0z"/>
        </svg>
        <div class="price-value-wrapper">
          <span class="mr-2 text-3xl">{{ $t('from') }}</span>
          <span class="price-value">500$</span>
        </div>
        <button class="price-button bg-zina-purple">
          <a :href="$i18n.locale === 'uk' ? '/uk/price/' : '/ru/price/'">{{ $t('Compare') }}</a>
        </button>
        <div class="h-7 w-48" style="background: transparent radial-gradient(closest-side at 50% 50%, #A288D8 0%, rgba(255,255,255,0) 100%) 0% 0% no-repeat padding-box;">

        </div>

      </div>

      <!-- 2 колонка -->

      <div class="price-col text-zina-teal">
        <div 
          class="baloon"
          style="box-shadow: 0px 0px 80px #007EC2;"
        >
          <icon-base
            width="82"
            height="61"
            viewBox="0 0 82 61"
            class="icon icon-site"
            style="color: #8bc3e1"
            :title="
              $i18n.locale === 'uk'
                ? 'Вартість створення корпоративного сайту'
                : 'Стоимость создания корпоративного сайта'
            "
            :desc="
              $i18n.locale === 'uk'
                ? 'Повноцінний сайт компанії: система управління сайтом та необмежену кількість сторінок. Найкращий варіант для іміджу та просування в Google.'
                : 'Полноценный сайт компании: система управления сайтом и неограниченное количество страниц. Лучший вариант для имиджа и продвижения в Google.'
            "
          ><icon-site /></icon-base>
          <h4 class="price-title">
            {{ $t('Corporate site') }}
          </h4>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 80 27" class="baloon-tile">
          <path fill="#FFF" d="M0 0s40 .3 40 27C40-1 80 0 80 0H0z"/>
        </svg>
        <div class="price-value-wrapper">
          <span class="mr-2 text-3xl">{{ $t('from') }}</span>
          <span class="price-value">1200$</span>
        </div>
        <button class="price-button bg-zina-teal">
          <a :href="$i18n.locale === 'uk' ? '/uk/price/' : '/ru/price/'">{{ $t('Compare') }}</a>
        </button>
        <div class="h-7 w-48" style="background: transparent radial-gradient(closest-side at 50% 50%, #007EC2 0%, rgba(255,255,255,0) 100%) 0% 0% no-repeat padding-box;">

        </div>

      </div>

      <!-- 3 колонка -->

      <div class="price-col text-picton-blue-500">
        <div 
          class="baloon"
          style="box-shadow: 0px 0px 80px #00ADEF;"
        >
          <icon-base
            width="101"
            height="61"
            viewBox="0 0 101 61"
            class="icon icon-shop"
            style="color: #90d4ee"
            :title="
              $i18n.locale === 'uk'
                ? 'Вартість створення інтернет-магазину'
                : 'Стоимость создания интернет-магазина'
            "
            :desc="
              $i18n.locale === 'uk'
                ? 'Ваш власний інтернет-магазин: зручний каталог, гарні картки товару, легке оформлення замовлення.'
                : 'Ваш собственный интернет-магазин: удобный каталог, красивые карточки товара, легкое оформление заказа.'
            "
          ><icon-shop /></icon-base>
          <h4 class="price-title">
            {{ $t('E-commerce') }}
          </h4>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 80 27" class="baloon-tile">
          <path fill="#FFF" d="M0 0s40 .3 40 27C40-1 80 0 80 0H0z"/>
        </svg>
        <div class="price-value-wrapper">
          <span class="mr-2 text-3xl">{{ $t('from') }}</span>
          <span class="price-value">1800$</span>
        </div>
        <button class="price-button bg-picton-blue-500">
          <a :href="$i18n.locale === 'uk' ? '/uk/price/' : '/ru/price/'">{{ $t('Compare') }}</a>
        </button>
        <div class="h-7 w-48" style="background: transparent radial-gradient(closest-side at 50% 50%, #00ADEF 0%, rgba(255,255,255,0) 100%) 0% 0% no-repeat padding-box;">

          <!-- background: rgb(0,173,239); -->
          <!-- background: radial-gradient(circle, rgba(0,173,239,1) 0%, rgba(255,255,255,0) 100%); -->

        </div>

      </div>

    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
const IconBase = defineAsyncComponent(() => import("@/components/IconBase"));
const IconLanding = defineAsyncComponent(() => import("@/components/icons/IconLanding"));
const IconSite = defineAsyncComponent(() => import("@/components/icons/IconSite"));
const IconShop = defineAsyncComponent(() => import("@/components/icons/IconShop"));
// import IconBase from '@/components/IconBase.vue'
// import IconLanding from '@/components/icons/IconLanding.vue'
// import IconSite from '@/components/icons/IconSite.vue'
// import IconShop from '@/components/icons/IconShop.vue'
export default {
  name: "PricesBlock",
  components: {
    IconBase,
    IconLanding,
    IconSite,
    IconShop,
  }
};

</script>

<style lang="scss" scoped>
  .price-container {
    background-image: url(/home/img/piggy-mask-280.webp); 
    background-position: left -100px bottom 70px; 
    @media (min-width: 1440px) {
      background-image: url(/home/img/piggy-mask-360.webp); 
      background-position: left -120px bottom 50px; 
    }
    @media (min-width: 1680px) {
      background-image: url(/home/img/piggy-mask.webp); 
      background-position: left -170px bottom 50px; 
    }
  }
  .price-col {
    @apply flex flex-col items-center;
    @media (orientation: portrait) {
      margin-bottom: 6rem;
    }
  }
  .baloon {
    @apply w-72 3xl:h-44 p-7 flex flex-col items-center bg-white;
    border-radius: 2rem; 
    // height: 60px;
    @media (min-width: 1440px) {
      height: 160px;
    }
  }
  .baloon-tile {
    @apply 3xl:mb-10 mx-auto mb-4;
    width: 80px;
    height: 27px;
  }
  .price-title {
    @apply font-bold uppercase whitespace-nowrap;
    font-size: 24px;
    @media (min-width: 1680px) {
      font-size: 28px;
    }
  }

  .icon {
    @apply mb-3;
    @media (orientation: landscape) {
      display: none;
    }
    @media (min-width: 1440px) {
      display: block;
    }
  }
  .icon-landing {
    width: 65px; 
    height: 55px;
    @media (min-width: 1680px) {
      width: 72px; 
      height: 61px;
    }
  }
  .icon-site {
    @apply mb-3;
    width: 74px; 
    height: 55px;
    @media (min-width: 1680px) {
      width: 82px; 
      height: 61px;
    }
  }
  .icon-shop {
    @apply mb-3;
    width: 91px; 
    height: 55px;
    @media (min-width: 1680px) {
      width: 101px; 
      height: 61px;
    }
  }
  .price-value-wrapper {
    @apply 3xl:mb-10 mb-6;
  }
  .price-value {
    @apply 3xl:text-6xl text-5xl font-semibold;
  }
  .price-button {
    @apply hover:bg-zina-gray-dark 3xl:px-10 px-8 3xl:py-3 py-1.5 3xl:pt-2 pt-1.5 3xl:mb-10 mb-6 3xl:text-xl text-lg tracking-wider text-white uppercase rounded-full;
  }
</style>