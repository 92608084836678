<template>
  <button class="hover:bg-yellow-400 hover:text-black hover:shadow-lg focus:ring focus:ring-yellow-400 focus:ring-opacity-50 focus:outline-none lg:uppercase text-gray-800 bg-yellow-300 rounded-full">
    <!-- <button class="hover:bg-red-700 hover:text-white hover:shadow-lg focus:ring focus:ring-yellow-400 focus:ring-opacity-50 focus:outline-none lg:uppercase hover:border-white hover:border-opacity-80 text-white bg-red-700 border-4 rounded-full"> -->
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "custom-button",
};
</script>

<style lang="scss" scoped>

</style>