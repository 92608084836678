<template>
  <div>
    <h1>Error page</h1>
  </div>
</template>
<script>
export default {
  name: "error",
};
</script>
<style lang="scss" scoped>
h1 {
  margin-top: 200px;
}
</style>
