export default {
	uk: {
		name: 'Укр',
		load: () => { return import('./uk.json'); },
	},
	ru: {
		name: 'Рус',
		load: () => { return import('./ru.json'); },
	},
};
