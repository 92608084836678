<template>
  <a
    href="#" v-scroll-to="nextAnchor"
    class=" land:bg-picton-blue-500 land:hover:bg-picton-blue-400 2xl:w-16 2xl:h-16 land:mb-5 sm:mb-3 absolute inset-x-0 bottom-0 flex items-center justify-center w-12 h-12 mx-auto mb-1 rounded-full"
    :aria-label="
      $i18n.locale === 'uk'
        ? 'Уперед'
        : 'Вперед'
    "
    :title="
      $i18n.locale === 'uk'
        ? 'Уперед'
        : 'Вперед'
    "
  >
    <span class="scroll-down__arrow_white scroll-down__arrow-first 2xl:ml-4 2xl:mt-3 absolute top-0 left-0 w-8 h-8 mt-1.5 ml-2 bg-contain"></span>
    <span class="scroll-down__arrow_white scroll-down__arrow-second 2xl:ml-4 2xl:mt-5 absolute top-0 left-0 w-8 h-8 mt-3 ml-2 bg-contain"></span>
  </a>
</template>

<script>
export default {
  name: "custom-anchor-button",
  props: {
    nextAnchor: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
$base: 0.6rem;

// .container {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   height: 100vh;
// }

// a {
//   position: absolute;
//   bottom: 0;
//   margin-bottom: 120px;
// }

// .container {
//   position: absolute;
//   bottom: 0;
//   margin-bottom: 120px;
// }


.scroll-down__arrow_white {
  background-image: url("data:image/svg+xml,%3Csvg class='bi bi-chevron-compact-down' viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67z'/%3E%3C/svg%3E");
}

@keyframes scroll-down__animation {
  0%  { opacity: 1; transform: translateY(0px) scale(1); }
  25% { opacity: 0; transform: translateY(10px) scale(0.9); }
  26% { opacity: 0; transform: translateY(-10px) scale(0.9); }
  55% { opacity: 1; transform: translateY(0px) scale(1); }
}

.scroll-down__arrow-first,
.scroll-down__arrow-second {
  animation-name: scroll-down__animation;
  animation-duration: 2.4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.scroll-down__arrow-first {
  animation-name: scroll-down__animation;
  animation-duration: 2.4s;
  animation-delay: 0.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}


</style>