<template>
  <div 
    class="section-order flex items-center justify-center w-full"
    :class="[
      $screen.landscape 
        ? 'h-screen xl:pt-14 pt-12' 
        : 'py-20 sm:px-8 md:px-16'
    ]"
  >
    <div 
      class="form-container max-w-max pb-14 flex flex-col pt-10"
      :class="[
        $screen.landscape 
          ? '3xl:px-40 3xl:py-16 px-20' 
          : 'px-8 sm:px-12 md:px-20'
      ]"
    >
      <h2 
        class=" font-roboto font-light tracking-tight text-center uppercase"
        :class="[
          $screen.landscape 
            ? 'h2-title-bold__smaller 3xl:mb-14 z-10 mb-8' 
            : 'h2-title-portrait mb-12'
        ]"
      >
        <span v-if="$i18n.locale === 'uk'">
          <span class="text-picton-blue-500">Напишіть</span> <span class="text-blue-dark font-extralight">нам</span>
        </span>
        <span v-if="$i18n.locale === 'ru'">
          <span class="text-picton-blue-500">Напишите</span> <span class="text-blue-dark font-extralight">нам</span>
        </span>
      </h2>

      <div class="text-blue-dark">
        <form-order 
          :formId="'form-bottom'"
          :formDescription="'Видимая форма внизу главной страницы'"
          :formCta="
            $i18n.locale === 'uk'
              ? 'НАПИШІТЬ НАМ'
              : 'НАПИШИТЕ НАМ'
          "
          :pageTitle="
            $i18n.locale === 'uk'
              ? 'Студія «Зіна дизайн»'
              : 'Студия «Зина дизайн»'
          "
        ></form-order>
      </div>

    </div>
  </div>
</template>

<script>

import { defineAsyncComponent } from "vue";
const FormOrder = defineAsyncComponent(() => import("@/components/UI/FormOrder"));

export default {
  name: "OrderBlock",
  components: {
    FormOrder,
  },
};


</script>

<style scoped lang="scss">
  .form-container {
    background-color: rgba(248, 248, 248, 0.9);
    box-shadow: 0px 0px 80px #00000029;
  }
  .section-order {
    background: linear-gradient(180deg, #0071B5 0%, #0072B6 52%, #0099DC 100%) 0% 0% no-repeat;
  } 
  @media (orientation: landscape) {
    .section-order {
      background: 
        url(/home/img/vesna/900/girl-900.webp) right -300px bottom 0 no-repeat,
        url(/home/img/vesna/900/cloud-900.webp) right -300px bottom 0 no-repeat,
        linear-gradient(180deg, #0071B5 0%, #0072B6 52%, #0099DC 100%) 0% 0% no-repeat;
    } 
  }
</style>
