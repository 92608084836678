<template>
  <div
    class=" sm:py-12 flex items-center justify-center w-screen min-h-screen px-8 py-20 overflow-hidden transition-opacity duration-1000 ease-out bg-gray-500"
    :class="[isBurgerActive ? 'bg-opacity-100' : 'bg-opacity-0']"
  >
    <!-- @click.prevent="toggle" -->
    <div
      class=" justify-evenly max-w-screen-2xl xl:flex-row land:w-10/12 flex flex-col items-start"
    >
      <div class="xl:w-auto xl:mb-0 flex flex-col items-center w-full mb-12">
        <ul
          class=" md:text-5xl lg:text-6xl md:leading-relaxed lg:leading-relaxed 2xl:leading-loose text-4xl leading-relaxed text-white"
        >
          <li>
            <a
              class="hover:border-b-4 hover:border-white whitespace-nowrap duration-200 transform"
              :href="$i18n.locale === 'uk' ? '/uk/about/' : '/ru/about/'"
              :aria-label="$t('About')"
              >{{ $t("About") }}</a
            >
          </li>
          <li>
            <a
              class="hover:border-b-4 hover:border-white whitespace-nowrap duration-200 transform"
              :href="$i18n.locale === 'uk' ? '/uk/portfolio/' : '/ru/portfolio/'"
              :aria-label="$t('Portfolio')"
              >{{ $t("Portfolio") }}</a
            >
          </li>
          <li>
            <a
              class="hover:border-b-4 hover:border-white whitespace-nowrap duration-200 transform"
              :href="$i18n.locale === 'uk' ? '/uk/services/' : '/ru/services/'"
              :aria-label="$t('Services')"
              >{{ $t("Services") }}</a
            >
          </li>
          <li>
            <a
              class="hover:border-b-4 hover:border-white whitespace-nowrap duration-200 transform"
              :href="$i18n.locale === 'uk' ? '/uk/price/' : '/ru/price/'"
              :aria-label="$t('Prices')"
              >{{ $t("Prices") }}</a
            >
          </li>
          <li>
            <a
              class="hover:border-b-4 hover:border-white whitespace-nowrap duration-200 transform"
              :href="$i18n.locale === 'uk' ? '/uk/contacts/' : '/ru/contacts/'"
              :aria-label="$t('Contacts')"
              >{{ $t("Contacts") }}</a
            >
          </li>
        </ul>
      </div>
      <div class="xl:w-auto xl:pt-8 w-full leading-loose text-white">
        <div class="xl:block flex justify-center">
          <div
            class="xl:text-left land:mb-0 2xl:pb-16 relative pb-12 text-center"
          >
            <div v-if="$screen.portrait">
              <button-morph-modal
                :id="2"
                :buttonWidth="'16rem'"
                :buttonHeight="'3.5rem'"
                :buttonTextSize="'1.5rem'"
                :buttonTextColor="'#374151'"
                :buttonTextColorHover="'white'"
                :buttonColorStatic="'white'"
                :buttonColor="'#B91C1C'"
                :buttonColorActive="'#DC2626'"
                :buttonScale="50"
              >
                {{ $t("To order a site") }}
              </button-morph-modal>
            </div>
            <div v-if="$screen.landscape">
              <button-morph-modal
                :id="2"
                :buttonWidth="'22rem'"
                :buttonHeight="'4.5rem'"
                :buttonTextSize="'2rem'"
                :buttonTextColor="'#374151'"
                :buttonTextColorHover="'white'"
                :buttonColorStatic="'white'"
                :buttonColor="'#B91C1C'"
                :buttonColorActive="'#DC2626'"
                :buttonScale="50"
              >
                {{ $t("To order a site") }}
              </button-morph-modal>
            </div>
          </div>
        </div>
        <div
          class=" xl:text-left xl:pl-10 xl:block flex flex-col items-center text-3xl text-center"
        >
          <p class="2xl:mb-4 mb-2">+38 050 348 17 11</p>
          <p
            class=" xl:text-left sm:max-w-sm xl:pr-8 2xl:mb-8 mb-4 text-xl leading-loose text-center"
          >
            <a class="xl:mr-6 xl:mx-0 inline-block mx-4" href="tg://resolve?domain=romychvk">Telegram</a>
            <a class="xl:mr-6 xl:mx-0 inline-block mx-4" href="viber://chat?number=+380503481711">Viber</a>
            <a class="xl:mr-6 xl:mx-0 inline-block mx-4" href="https://wa.me/380503481711">WhatsApp</a>
            <a class="xl:mr-6 xl:mx-0 inline-block mx-4" href="skype:zinadesign?chat">Skype</a>
            <a class="xl:mr-6 xl:mx-0 inline-block mx-4" href="https://m.me/Zina.Design">Facebook Messenger</a>
          </p>
          <p class="2xl:mb-8 mb-4">
            <a href="mailto:mail@zina.design">mail@zina.design</a>
          </p>
          <p class="">{{ $t("Dnipro, Korolenko 1") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { defineAsyncComponent } from "vue";
import { mapGetters } from "vuex";
const ButtonMorphModal = defineAsyncComponent(() =>
  import("@/components/UI/ButtonMorphModal")
);
export default {
  name: "burger-modal",
  components: {
    ButtonMorphModal,
  },
  computed: {
    ...mapGetters({
      isBurgerActive: "select/selectIsBurgerActive",
    }),
  },
};
</script>
