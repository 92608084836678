export const modalModule = {

    state() {
        return {
            modalVisible: false,
        }
    },

    getters: {
        modalVisible(state) {
            return state.modalVisible;
        },
    },

    mutations: {
        setModalVisible(state, data) {
            state.modalVisible = data;
        },
    },

    actions: {

    },

    namespaced: true,

};