<template>
  <div 
    v-if="$screen.landscape"
    class="absolute bottom-0 right-0 flex justify-center w-1/2 pb-4"
  >
    <button class="btn-next-section relative block">
      <a 
        class="flex flex-col items-center" 
        href="#" 
        v-scroll-to="nextAnchor"
      >
        <div class="btn-next-section-rect-blue"></div>
        <div class="btn-next-section-rect-yellow"></div>
        <div class="btn-next-section__title_purple">
          <slot></slot>
        </div>
        <div class="btn-next-section__arrow">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.7 56.7">
            <g fill="#8969cb">
              <path d="M32.7 40.4 16.4 56.7 0 40.4l.7-.8 15.7 15.7L32 39.6Z"/>
              <path d="M16.9 56h-1V0h1Z"/>
            </g>
          </svg>
        </div>
      </a>
    </button>
    </div>
</template>

<script>
  export default {
    name: "button-next-section-yellow",
    props: {
      nextAnchor: {
        type: String,
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
