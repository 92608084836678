<template>
  <!-- Для мобильных -->
  <div
    v-if="$screen.portrait"
    class=" relative flex flex-col items-center justify-center w-full h-full mb-32"
  >
    <div class="relative w-full overflow-hidden">
      <div class="vesna_cloud__mobile min-w-max mt-10">
        <picture>
          <source
            srcset="/home/img/vesna/900/cloud-900.webp"
            media="(min-width: 1280px)"
          />
          <source
            srcset="/home/img/vesna/1400/cloud-1400.webp"
            media="(min-width: 1024px)"
          />
          <source
            srcset="/home/img/vesna/900/cloud-900.webp"
            media="(min-width: 760px)"
          />
          <source
            srcset="/home/img/vesna/800/cloud-800.webp"
            media="(min-width: 600px)"
          />
          <source
            srcset="/home/img/vesna/700/cloud-700.webp"
            media="(min-width: 428px)"
          />
          <source
            srcset="/home/img/vesna/600/cloud-600.webp"
            media="(min-width: 412px)"
          />
          <img
            src="/home/img/vesna/500/cloud-500.webp"
            class="mt-16"
            :alt="
              $i18n.locale === 'uk'
                ? 'Хмара'
                : 'Облако'
            "
          />
        </picture>
      </div>
      <div class="vesna_flowers__mobile min-w-max absolute top-0 left-0">
        <picture>
          <source
            srcset="/home/img/vesna/1400/flowers-1400.webp"
            media="(min-width: 1280px)"
          />
          <source
            srcset="/home/img/vesna/1200/flowers-1200.webp"
            media="(min-width: 1024px)"
          />
          <source
            srcset="/home/img/vesna/900/flowers-900.webp"
            media="(min-width: 760px)"
          />
          <source
            srcset="/home/img/vesna/800/flowers-800.webp"
            media="(min-width: 600px)"
          />
          <source
            srcset="/home/img/vesna/700/flowers-700.webp"
            media="(min-width: 428px)"
          />
          <source
            srcset="/home/img/vesna/600/flowers-600.webp"
            media="(min-width: 412px)"
          />

          <img
            src="/home/img/vesna/500/flowers-500.webp"
            class="mt-6"
            :alt="
              $i18n.locale === 'uk'
                ? 'Квіти'
                : 'Цветы'
            "
          />
        </picture>
      </div>
      <div class="vesna_girl__mobile min-w-max absolute top-0 left-0">
        <picture>
          <source
            srcset="/home/img/vesna/1400/girl-1400.webp"
            media="(min-width: 1280px)"
          />
          <source
            srcset="/home/img/vesna/1200/girl-1200.webp"
            media="(min-width: 1024px)"
          />
          <source
            srcset="/home/img/vesna/900/girl-900.webp"
            media="(min-width: 760px)"
          />
          <source
            srcset="/home/img/vesna/800/girl-800.webp"
            media="(min-width: 600px)"
          />
          <source
            srcset="/home/img/vesna/700/girl-700.webp"
            media="(min-width: 428px)"
          />
          <source
            srcset="/home/img/vesna/600/girl-600.webp"
            media="(min-width: 412px)"
          />
          <img
            src="/home/img/vesna/500/girl-500.webp"
            class="mt-6"
            :alt="
              $i18n.locale === 'uk'
                ? 'Дівчинка-весна'
                : 'Девочка-весна'
            "
          />
        </picture>
      </div>
    </div>
  </div>

  <!-- Для десткопов -->
  <kinesis-container
    v-if="$screen.landscape"
    class="flex items-center justify-center w-full h-full pt-0 overflow-hidden"
    easing="cubic-bezier(.25,0.5,.45,1)"
    :duration="4000"
  >
    <div class="land:mr-72 ml-36 land:ml-0">
      <kinesis-element type="translate" :strength="50">
        <div class="vesna_cloud min-w-max">
          <picture>
            <!-- <source srcset="/home/img/vesna/1400/cloud-1400-30colors.png" media="(min-width: 2048px)">
            <source srcset="/home/img/vesna/1200/cloud-1200-16colors.png" media="(min-width: 1536px)">
            <source srcset="/home/img/vesna/900/cloud-900-30colors.png" media="(min-width: 1280px)">
            <source srcset="/home/img/vesna/1400/cloud-1400-30colors.png" media="(min-width: 1024px)">
            <source srcset="/home/img/vesna/900/cloud-900-30colors.png" media="(min-width: 760px)">
            <source srcset="/home/img/vesna/800/cloud-800-30colors.png" media="(min-width: 600px)"> -->
            <source
              srcset="/home/img/vesna/1600/cloud-1600.webp"
              media="(min-width: 2560px)"
            />
            <source
              srcset="/home/img/vesna/1400/cloud-1400.webp"
              media="(min-width: 2048px)"
            />
            <source
              srcset="/home/img/vesna/1200/cloud-1200.webp"
              media="(min-width: 1440px)"
            />
            <source
              srcset="/home/img/vesna/900/cloud-900.webp"
              media="(min-width: 600px)"
            />
            <img
              src="/home/img/vesna/800/cloud-800.webp"
              :alt="
                $i18n.locale === 'uk'
                  ? 'Хмара'
                  : 'Облако'
              "
            />
          </picture>
        </div>
      </kinesis-element>
      <kinesis-element type="translate" :strength="145">
        <div class="vesna_flowers min-w-max">
          <picture>
            <!-- <source srcset="/home/img/vesna/1400/flowers-1400-30colors.png" media="(min-width: 2048px)">
            <source srcset="/home/img/vesna/1200/flowers-1200-30colors.png" media="(min-width: 1536px)">
            <source srcset="/home/img/vesna/900/flowers-900-30colors.png" media="(min-width: 1280px)">
            <source srcset="/home/img/vesna/1400/flowers-1400-30colors.png" media="(min-width: 1024px)">
            <source srcset="/home/img/vesna/900/flowers-900-30colors.png" media="(min-width: 760px)">
            <source srcset="/home/img/vesna/800/flowers-800-30colors.png" media="(min-width: 600px)"> -->
            <source
              srcset="/home/img/vesna/1600/flowers-1600.webp"
              media="(min-width: 2560px)"
            />
            <source
              srcset="/home/img/vesna/1400/flowers-1400.webp"
              media="(min-width: 2048px)"
            />
            <source
              srcset="/home/img/vesna/1200/flowers-1200.webp"
              media="(min-width: 1440px)"
            />
            <source
              srcset="/home/img/vesna/900/flowers-900.webp"
              media="(min-width: 600px)"
            />
            <img
              src="/home/img/vesna/800/flowers-800.webp"
              :alt="
                $i18n.locale === 'uk'
                  ? 'Квіти'
                  : 'Цветы'
              "
            />
          </picture>
        </div>
      </kinesis-element>
      <kinesis-element type="translate" :strength="150">
        <div class="vesna_girl min-w-max">
          <picture>
            <source
              srcset="/home/img/vesna/1600/girl-1600.webp"
              media="(min-width: 2560px)"
            />
            <source
              srcset="/home/img/vesna/1400/girl-1400.webp"
              media="(min-width: 2048px)"
            />
            <source
              srcset="/home/img/vesna/1200/girl-1200.webp"
              media="(min-width: 1440px)"
            />
            <source
              srcset="/home/img/vesna/900/girl-900.webp"
              media="(min-width: 600px)"
            />
            <img
              src="/home/img/vesna/800/girl-800.webp"
              :alt="
                $i18n.locale === 'uk'
                  ? 'Дівчинка-весна'
                  : 'Девочка-весна'
              "
            />
          </picture>
        </div>
      </kinesis-element>
    </div>
  </kinesis-container>

  <h1
    v-if="$screen.portrait"
    class="h1-mobile"
  >
    <span v-if="$i18n.locale === 'uk'">
      Створення сайтів у&nbsp;Дніпрі
    </span>
    <span v-if="$i18n.locale === 'ru'">
      Создание сайтов в&nbsp;Днепре
    </span>
  </h1>
  <div
    class="absolute flex flex-col"
    :class="[
      $screen.landscape
        ? 'bg-picton-blue-900 bg-opacity-75 justify-evenly right-0 w-1/2 pl-20 h-full pt-16 text-left pb-12 items-start xl:pl-16 2xl:pl-20'
        : 'message-mobile w-full bottom-6 sm:bottom-12 md:bottom-16 text-center items-center justify-end',
    ]"
  >
    <h1
      v-if="$screen.landscape"
      class="h1-desktop"
    >
      <span v-if="$i18n.locale === 'uk'">
        Студія «<span class="font-bold">Зіна&nbsp;дизайн</span>»&nbsp; 
        <br /><span class="font-bold">створення&nbsp;сайтів</span>
        <br />у&nbsp;Дніпрі
      </span>
      <span v-if="$i18n.locale === 'ru'">
        Студия «<span class="font-bold">Зина&nbsp;дизайн</span>»&nbsp; 
        <br /><span class="font-bold">создание&nbsp;сайтов</span>
        <br />в&nbsp;Днепре
      </span>
    </h1>
    <p
      v-if="$screen.height > '530'"
      class=" text-shadow 3xl:leading-snug 2xl:leading-snug xl:leading-snug lg:leading-tight md:leading-tight sm:leading-tight text-white"
      :class="[
        $screen.landscape
          ? 'text-picton-blue-100 bg-transparent p-0 mb-0 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-4xl md:text-4xl sm:text-3xl'
          : 'bg-picton-blue-900 bg-opacity-50  min-w-min w-60 sm:w-3/5 lg:w-3/5 px-10 pt-4 pb-6  mb-6 sm:mb-10 text-xl md:rounded-2xl rounded-xl md:px-16 md:pt-6 md:pb-10 lg:px-12 lg:pt-8 lg:pb-10 3xl:text-5xl 2xl:text-4xl xl:text-3xl lg:text-5xl md:text-4xl sm:text-3xl',
      ]"
    >
      <span v-if="$i18n.locale === 'uk'">
        Робимо сайти,<br />
        які літають<br />
        і радують
      </span>
      <span v-if="$i18n.locale === 'ru'">
        Делаем сайты,<br />
        которые летают<br />
        и радуют
      </span>
      
      <!-- *включая: лендинги, интернет-магазины, корпоративные сайты, сайты-визитки, порталы, новостные сайты, промо-сайты, персональные сайты, сайты госорганов, сайты некоммерческих организаций, сайты правозащитных организаций, сайты музеев -->
      <svg
        viewBox="0 0 130 130"
        class="3xl:h-10 md:h-8 inline w-auto h-5"
        alt="❤"
        width="50"
        height="50"
      >
        <path
          d="M65 29c-6-10-16-17-28-17C20 12 7 25 7 42c0 33 18 38 58 76 40-38 58-43 58-76 0-17-13-30-30-30-12 0-22 7-28 17z"
          fill="#ED2027"
        />
        <!-- Original color: #ff0707 -->
        <!-- Zina logo color: #e72b31 -->
      </svg>
    </p>

    <!-- Кнопка для старых мобильников -->
    <button-morph-modal
      v-if="$screen.width < '600'"
      :id="0"
      :buttonWidth="'15rem'"
      :buttonHeight="'3rem'"
      :buttonTextSize="'1.3rem'"
      :buttonScale="50"
    >
      <span class="font-semibold">{{ $t("To order a site") }}</span>
    </button-morph-modal>

    <!-- Кнопка для мобильной версии -->
    <button-morph-modal
      v-if="
        ($screen.width >= '600' && $screen.width < '760') ||
        ($screen.landscape && $screen.width >= '760' && $screen.width < '1536')
      "
      :id="0"
      :buttonWidth="'18rem'"
      :buttonHeight="'3.7rem'"
      :buttonTextSize="'1.5rem'"
      :buttonScale="50"
    >
      {{ $t("To order a site") }}
    </button-morph-modal>

    <!-- Кнопка для десктопной версии -->
    <button-morph-modal
      v-if="
        ($screen.portrait && $screen.width >= '760') ||
        ($screen.landscape && $screen.width >= '1536')
      "
      :id="0"
      :buttonWidth="'24rem'"
      :buttonHeight="'5rem'"
      :buttonTextSize="'2.25rem'"
      :buttonScale="40"
    >
      {{ $t("To order a site") }}
    </button-morph-modal>
  </div>
</template>

<script>
import { KinesisContainer, KinesisElement } from "vue-kinesis";
import { defineAsyncComponent } from "vue";
import { useGrid } from "vue-screen";
import tailwindConfig from "@/../tailwind.config.js";

const ButtonMorphModal = defineAsyncComponent(() =>
  import("@/components/UI/ButtonMorphModal")
);

export default {
  name: "ParallaxBlock",
  components: {
    KinesisContainer,
    KinesisElement,
    ButtonMorphModal,
  },
  setup() {
    const grid = useGrid(tailwindConfig.theme.screens);
    return {
      grid,
    };
  },
};
</script>

<style lang="scss" scoped>

.h1-mobile {
  @apply text-shadow top-10 sm:text-5xl md:text-6xl lg:text-7xl sm:leading-tight md:leading-tight sm:pt-8 md:pt-12 lg:pt-16 lg:px-16 bg-gradient-to-b from-picton-blue-900 via-picton-blue-900 to-transparent absolute w-full px-4 pt-4 pb-3 mx-4 text-3xl font-bold leading-tight text-center text-white uppercase;
}
.h1-desktop {
  @apply land:block 3xl:text-6xl 2xl:text-5xl xl:text-4xl lg:text-4xl hidden text-3xl font-normal text-white; 
}
.text-shadow {
  text-shadow: #03446b 0 1px 5px;
}
// Mobile
.vesna_cloud__mobile {
  margin-left: -100px;
}
@media (min-width: 412px) {
  .vesna_cloud__mobile {
    margin-left: -120px;
  }
}
@media (min-width: 428px) {
  .vesna_cloud__mobile {
    margin-left: -160px;
  }
}
@media (min-width: 760px) {
  .vesna_cloud__mobile {
    margin-left: -120px;
  }
}
@media (min-width: 1024px) {
  .vesna_cloud__mobile {
    margin-left: -240px;
  }
}

.vesna_girl__mobile {
  animation: girl-swimming__mobile 40s cubic-bezier(0.36, 0.07, 0.19, 0.97) 1s
    infinite;
}
.vesna_flowers__mobile {
  animation: flowers-swimming__mobile 40s cubic-bezier(0.36, 0.07, 0.19, 0.97)
    1.1s infinite;
}
.vesna_cloud__mobile {
  animation: cloud-swimming__mobile 40s cubic-bezier(0.36, 0.07, 0.19, 0.97)
    1.3s infinite;
}

@keyframes girl-swimming__mobile {
  13% {
    transform: translate3d(-80px, 0, 0);
  }
  30% {
    transform: translate3d(80px, 0, 0);
  }
  45% {
    transform: translate3d(-50px, 0, 0);
  }
  60% {
    transform: translate3d(50px, 0, 0);
  }
  80% {
    transform: translate3d(-25px, 0, 0);
  }
  95% {
    transform: translate3d(0px, 0, 0);
  }
}
@keyframes flowers-swimming__mobile {
  13% {
    transform: translate3d(-75px, 0, 0);
  }
  30% {
    transform: translate3d(80px, 0, 0);
  }
  45% {
    transform: translate3d(-45px, 0, 0);
  }
  60% {
    transform: translate3d(50px, 0, 0);
  }
  80% {
    transform: translate3d(-20px, 0, 0);
  }
  95% {
    transform: translate3d(0px, 0, 0);
  }
}
@keyframes cloud-swimming__mobile {
  13% {
    transform: translate3d(-20px, 0, 0);
  }
  30% {
    transform: translate3d(20px, 0, 0);
  }
  45% {
    transform: translate3d(-10px, 0, 0);
  }
  60% {
    transform: translate3d(10px, 0, 0);
  }
  80% {
    transform: translate3d(-5px, 0, 0);
  }
  95% {
    transform: translate3d(0px, 0, 0);
  }
}

.message-mobile {
  @media (min-width: 412px) {
    margin-bottom: 2rem;
  }
}

// Desktop

.vesna_girl {
  animation: girl-swimming 60s cubic-bezier(0.36, 0.07, 0.19, 0.97) 1s infinite;
}
.vesna_flowers {
  position: fixed;
  animation: flowers-swimming 60s cubic-bezier(0.36, 0.07, 0.19, 0.97) 1.1s
    infinite;
}
.vesna_cloud {
  position: fixed;
  animation: cloud-swimming 60s cubic-bezier(0.36, 0.07, 0.19, 0.97) 1.3s
    infinite;
}

@keyframes girl-swimming {
  13% {
    transform: translate3d(-200px, 0, 0);
  }
  30% {
    transform: translate3d(200px, 0, 0);
  }
  45% {
    transform: translate3d(-100px, 0, 0);
  }
  60% {
    transform: translate3d(100px, 0, 0);
  }
  80% {
    transform: translate3d(-50px, 0, 0);
  }
  95% {
    transform: translate3d(0px, 0, 0);
  }
}
@keyframes flowers-swimming {
  13% {
    transform: translate3d(-195px, 0, 0);
  }
  30% {
    transform: translate3d(200px, 0, 0);
  }
  45% {
    transform: translate3d(-95px, 0, 0);
  }
  60% {
    transform: translate3d(100px, 0, 0);
  }
  80% {
    transform: translate3d(-45px, 0, 0);
  }
  95% {
    transform: translate3d(0px, 0, 0);
  }
}
@keyframes cloud-swimming {
  13% {
    transform: translate3d(-40px, 0, 0);
  }
  30% {
    transform: translate3d(40px, 0, 0);
  }
  45% {
    transform: translate3d(-20px, 0, 0);
  }
  60% {
    transform: translate3d(20px, 0, 0);
  }
  80% {
    transform: translate3d(-10px, 0, 0);
  }
  95% {
    transform: translate3d(0px, 0, 0);
  }
}
</style>