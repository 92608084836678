<template>
  <metainfo></metainfo>
  <NavBar />
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import { setLanguage } from "./vue-zina-router";
const NavBar = defineAsyncComponent(() => import("@/components/NavBar.vue"));

export default {
  setup() {
    return { setLanguage };
  },
  components: {
    NavBar,
  },
  // metaInfo() {
  //   return { 
  //     title: "Epiloge - Build your network in your field of interest",
  //     meta: [
  //       { name: 'description', content:  'Epiloge is about connecting in your field of interest. Our vision is to help people share their knowledge, work, projects, papers and ideas and build their network through what they do rather where they live, study or work.'},
  //       { property: 'og:title', content: "Epiloge - Build your network in your field of interest"},
  //       { property: 'og:site_name', content: 'Epiloge'},
  //       {property: 'og:type', content: 'website'},    
  //       {name: 'robots', content: 'index,follow'} 
  //     ]
  //   }
  // },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.setLocale();
      let icon = null;
      let iconArray = null;
      icon = document.getElementById("icon");
      iconArray = icon.href.split("/");
      if (oldVal === "uk") {
        iconArray[iconArray.length - 1] = iconArray[
          iconArray.length - 1
        ].replace("uk", "ru");
      }
      if (oldVal === "ru") {
        iconArray[iconArray.length - 1] = iconArray[
          iconArray.length - 1
        ].replace("ru", "uk");
      }
      icon.href = iconArray.join("/");
    },

    '$route': {
      handler() {
        this.setLocale();
      },
      immediate: true
    }
  },

  methods:{
    // применение переводов к заголовку и мета-тегам
    setLocale(){
      // для разных страниц, можно писать ключ в перефодах по имени роута
      // let name = this.$route.name ? this.$route.name : "Home";

      // Переменные с значениями для мета-тегов и заголовка
      let title = this.$t('title');
      let description = this.$t('description');

      document.title = title;

      // получение мета-тегов по аттрибуту vmid
      let descriptionMeta = document.querySelector("[vmid='description']");
      let ogDescriptionMeta = document.querySelector("[vmid='og:description']");
      let ogTitleMeta = document.querySelector("[vmid='og:title']");

      // обновление аттрибута content
      if(descriptionMeta) descriptionMeta.setAttribute("content", description);
      if(ogDescriptionMeta) ogDescriptionMeta.setAttribute("content", description);
      if(ogTitleMeta) ogTitleMeta.setAttribute("content", title);

    },
  },

  mounted() {
    let array = document.location.pathname.split("/");
    // console.log(array[1]);
    if (array[1] === "ru" || array[1] === "uk") {
      this.setLanguage(array[1]);
    }
  },
};
</script>

<style lang="scss">
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  position: relative;
  // scroll-behavior: smooth;
}
#app {
  height: 100vh;
}
</style>
