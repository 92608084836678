<template>
  <div class="flex flex-col justify-center w-full h-full">
    <div 
      class="container-gradient h-2/3 land:top-14 absolute top-0 flex justify-end w-full"
      style="z-index: -1;"
    >
      <!-- Спираль на фоне -->
      <div 
        class="w-1/2 pl-24"
        :class="[
          $screen.landscape 
            ? 'pt-1' 
            : 'hidden sm:block pt-44'
        ]"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 516.9 487.8" class="spiral">
          <g transform="rotate(-91 243.9 239.8)">
            <path fill="#fff" d="M407.9 423.2a300.4 300.4 0 0 0-31-419.6V0h-6v6h4.6a297.6 297.6 0 0 1 30 415.6H402v4.3a238 238 0 0 1-324 30v-2.6h-3.2l-6-4.9a190.2 190.2 0 0 1-21.6-266h3.5v-4.1a151.9 151.9 0 0 1 204.7-19.7v2.8h3.6l3.7 3A121.2 121.2 0 0 1 279 331H276v3.5l-.7.8a96.6 96.6 0 0 1-132.6 13.2v-3.8h-4.5A77 77 0 0 1 128 240.7h3.3v-4a61.4 61.4 0 0 1 79.7-11.2v3.2h4.5c.7.6 1.5 1 2.1 1.6a48.8 48.8 0 0 1 5.7 68.2h-4.4v4.5a38.8 38.8 0 0 1-48 2.3V302h-6v6h4.7a41.6 41.6 0 0 0 51.9-3.4h3.4v-3.6a51.6 51.6 0 0 0-5.5-72.7l-2.4-1.8v-3.7h-5.1a64.1 64.1 0 0 0-82.6 12h-4v5A79.9 79.9 0 0 0 136.8 347v3.7h4.4A99.4 99.4 0 0 0 277.5 337l.2-.1h4.4v-5.6a124 124 0 0 0-17.7-169l-3-2.5v-4.4h-5.7a154.7 154.7 0 0 0-207 21h-4v4.6a193 193 0 0 0 22.2 269.5l5 4v4.8h6A240.8 240.8 0 0 0 404 427.6h3.9Z" />
          </g>
        </svg>
      </div>
    </div>
    <div 
      class=""
      :class="[
        $screen.landscape 
          ? '3xl:mb-2 flex justify-end w-1/2 mt-16' 
          : 'flex justify-center w-full mt-24'
      ]"
    >
      <h2 
        class="font-roboto mx-4 text-white uppercase"
        :class="[
        $screen.landscape 
          ? 'h2-title-bold text-right font-light tracking-tight' 
          : 'h2-title-portrait mb-12'
      ]"
      >
        <span v-if="$i18n.locale === 'uk'">Дизайн із&nbsp;серцем<br><span class="font-extralight">та&nbsp;розумом</span></span>
        <span v-if="$i18n.locale === 'ru'">Дизайн с&nbsp;умом<br><span class="font-extralight">и&nbsp;сердцем</span></span>
      </h2>
    </div>
  <!-- ------------------------------------------------->
  <!-- Mobile -->
  <!-- ------------------------------------------------->

    <div
      v-if="$screen.portrait"
      class="flex flex-col items-center justify-center w-full"
    > 
      <div class="description-main text-white">
        <p 
          class="mb-6"
          v-if="$i18n.locale === 'uk'"
        >
          Ми намалювали
          <a class="link-white-yellow" href="/uk/portfolio/">сотні дизайнів</a> для
          <a class="link-white-yellow" href="/uk/portfolio/categories/"
            >десятків тематик</a
          >
          — від&nbsp;бізнесу до державних організацій
          <span class="whitespace-nowrap">та арт-проектів.</span>
        </p>
        <p 
          class="mb-6"
          v-if="$i18n.locale === 'ru'"
        >
          Мы нарисовали
          <a class="link-white-yellow" href="/ru/portfolio/">сотни дизайнов</a> для
          <a class="link-white-yellow" href="/ru/portfolio/categories/"
            >десятков тематик</a
          >
          — от&nbsp;бизнеса до государственных организаций
          <span class="whitespace-nowrap">и арт-проектов.</span>
        </p>
        <p v-if="$i18n.locale === 'uk'" class="mb-6">Усі — гарні.</p>
        <p v-if="$i18n.locale === 'ru'" class="mb-6">Все — красивые.</p>
      </div>
      <div class="content 3xl:pt-8 3xl:px-8 xl:px-6 justify-center w-full">
        <Splide
          :options="{
            width: '100%',
            speed: '800',
            type: 'loop',
            autoplay: false,
            focus: 'center',
            pauseOnHover: true,
            updateOnMove: true,
            arrows: false,
            pagination: false,
            lazyLoad: 'nearby',
            preloadPages: '1',
            perMove: 1,
            perPage: 7,
            breakpoints: {
              2048: {
                perPage: 6,
              },
              1536: {
                perPage: 5.5,
              },
              1366: {
                perPage: 4.5,
              },
              1024: {
                perPage: 2.5,
              },
              834: {
                perPage: 2,
              },
              640: {
                perPage: 2,
              },
              428: {
                perPage: 1,
              },
            },
          }"
        >
          <SplideSlide v-for="(slide, index) in sliderImages" :key="index">
            <div class="splide__slide-container portfolio-card__mobile">
              <img 
                :src="slide.image" 
                :alt="slide.title[$i18n.locale]" 
              />
              <div class="card-label__mobile">
                <a
                  class="card-link"
                  :style="{ color: slide.color }"
                  href="#"
                  v-html="slide.title[$i18n.locale]"
                />
              </div>
            </div>
          </SplideSlide>
          <template #before-track>
            <div class="splide__arrows text-white">
              <button class="splide__arrow splide__arrow--prev">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 60" width="20" height="60">
                  <path fill="currentColor" d="M3.7 60 0 58.1 15.3 30 0 1.9 3.7 0 20 30 3.7 60z"/>
                </svg>
              </button>
              <button class="splide__arrow splide__arrow--next">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 60" width="20" height="60">
                  <path fill="currentColor" d="M3.7 60 0 58.1 15.3 30 0 1.9 3.7 0 20 30 3.7 60z"/>
                </svg>
              </button>
            </div>
          </template>
        </Splide>
      </div>
      <p class="purple-circle mb-12">
        <span v-if="$i18n.locale === 'uk'">
          Ще більше робіт<br />дивіться в&nbsp;нашому<br />
          <a class="3xl:mt-3 block mt-1 uppercase" href="/uk/portfolio/">
            <span class="hover:text-zina-yellow">портфоліо</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.7 32.7" class="portfolio-arrow">
              <g fill="#fff">
                <path d="m25.4 32.7 16.3-16.3L25.4 0l-.8.7 15.7 15.7L24.6 32Z" />
                <path d="M41 16.9v-1H0v1Z" />
              </g>
            </svg>
          </a>
        </span>
        <span v-if="$i18n.locale === 'ru'">
          Еще больше работ<br />смотрите в&nbsp;нашем<br />
          <a class="3xl:mt-3 block mt-1 uppercase" href="/ru/portfolio/">
            <span class="hover:text-zina-yellow">портфолио</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.7 32.7" class="portfolio-arrow">
              <g fill="#fff">
                <path d="m25.4 32.7 16.3-16.3L25.4 0l-.8.7 15.7 15.7L24.6 32Z" />
                <path d="M41 16.9v-1H0v1Z" />
              </g>
            </svg>
          </a>
        </span>
      </p>
    </div>
  <!-- ------------------------------------------------->
  <!-- Desktop -->
  <!-- ------------------------------------------------->
    <div
      v-if="$screen.landscape"
      class="pl-36 3xl:mt-8 3xl:mt-4 2xl:mb-8 relative flex justify-center w-full pr-12 mb-4"
    >
      <Splide
        :options="{
          //type: 'loop',
          start: '1',
          breakpoints: {
            600: {
              start: '2',
            },
          },
          speed: '800',
          perPage: '1',
          perMove: '1',
          focus: '1',
          autoplay: false,
          updateOnMove: true,
          pagination: false,
          lazyLoad: 'nearby',
          preloadPages: '1',
          autoWidth: true,
          width: '100%',
          classes: {
            arrows: 'splide__arrows hidden',
            pagination: 'splide__pagination splide__pagination-design-desktop', // container
          },
        }"
      >
        <SplideSlide>
          <div class="portfolio-card md:text-2xl lg:text-3xl xl:text-xl 3xl:text-3xl 3xl:leading-normal 3xl:leading-snug 3xl:mb-12 xl:mt-4 relative justify-between mb-6 text-xl text-white">
            <div class="mb-12">
              <p class="mb-10" v-if="$i18n.locale === 'uk'">
                Ми намалювали <a class="link-white-red" href="/uk/portfolio/">сотні дизайнів</a> для <a class="link-white-red" href="/uk/portfolio/categories/">десятків тематик</a> — від&nbsp;бізнесу до державних організацій <span class="whitespace-nowrap">та арт-проектів.</span>
              </p>
              <p class="mb-10" v-if="$i18n.locale === 'ru'">
                Мы нарисовали <a class="link-white-red" href="/ru/portfolio/">сотни дизайнов</a> для <a class="link-white-red" href="/ru/portfolio/categories/">десятков тематик</a> — от&nbsp;бизнеса до государственных организаций <span class="whitespace-nowrap">и арт-проектов.</span>
              </p>
              <p v-if="$i18n.locale === 'uk'" class="font-bold">Усі гарні.</p>
              <p v-if="$i18n.locale === 'ru'" class="font-bold">Все красивые.</p>
            </div>
            <div class="text-zina-purple mb-12 text-xl uppercase">
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 56.7 32.7"
                class="text-zina-purple w-14 inline-block mb-1 mr-2"
              >
                <g fill="currentColor">
                  <path d="M16.4 32.7 0 16.4 16.4 0l.7.7L1.4 16.4 17.1 32Z" />
                  <path d="M.7 16.9v-1h56v1Z" />
                </g>
              </svg>
              {{ $t('Swipe') }}
            </div>
          </div>
        </SplideSlide>
        <!-- Start slider -->
        <SplideSlide v-for="(slide, index) in sliderImages" :key="index">
          <kinesis-container class="portfolio-card group">
            <kinesis-element
              class="card-img_wrapper"
              :strength="15"
              type="depth"
            >
              <img 
                class="card-img" 
                :src="slide.image" 
                :alt="slide.title[$i18n.locale]"
              />
            </kinesis-element>
            <kinesis-element
              class="card-border"
              :strength="15"
              type="depth"
            ></kinesis-element>
            <kinesis-element class="card-label" :strength="15" type="depth">
              <a
                class="card-link"
                :style="{ color: slide.color }"
                href="#"
                v-html="slide.title[$i18n.locale]"
              />
            </kinesis-element>
          </kinesis-container>
        </SplideSlide>
        <!-- End slider -->
        <SplideSlide>
          <div class="portfolio-card 3xl:pb-16 justify-center pt-4 pb-10 pl-0 pr-2">
            <p class="purple-circle">
              <span v-if="$i18n.locale === 'uk'">
                Ще більше робіт<br />дивіться в&nbsp;нашому<br />
                <a class="3xl:mt-3 block mt-1 uppercase" href="/uk/portfolio/">
                  портфоліо
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.7 32.7" class="portfolio-arrow">
                    <g fill="#fff">
                      <path d="m25.4 32.7 16.3-16.3L25.4 0l-.8.7 15.7 15.7L24.6 32Z" />
                      <path d="M41 16.9v-1H0v1Z" />
                    </g>
                  </svg>
                </a>
              </span>
              <span v-if="$i18n.locale === 'ru'">
                Еще больше работ<br />смотрите в&nbsp;нашем<br />
                <a class="3xl:mt-3 block mt-1 uppercase" href="/ru/portfolio/">
                  портфолио
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.7 32.7" class="portfolio-arrow">
                    <g fill="#fff">
                      <path d="m25.4 32.7 16.3-16.3L25.4 0l-.8.7 15.7 15.7L24.6 32Z" />
                      <path d="M41 16.9v-1H0v1Z" />
                    </g>
                  </svg>
                </a>
              </span>
            </p>
          </div>
        </SplideSlide>
      </Splide>
    </div>
  </div>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { KinesisContainer, KinesisElement } from "vue-kinesis";

const dataImages = [
  {
    color: "white",
    image: "/home/img/thumb/perspectiva-corp.webp",
    title: {
      uk: "Сайт будівельної<br />компанії<br />«Перспектива»",
      ru: "Сайт строительной<br />компании<br />«Перспектива»",
    },
  },
  {
    color: "black",
    image: "/home/img/thumb/udom.webp",
    title: {
      uk: "Лендінг компанії<br>«Затишний&nbsp;Дім»",
      ru: "Лендинг компании<br>«Уютный&nbsp;Дом»",
    }
  },
  {
    image: "/home/img/thumb/searchtop.webp",
    color: "white",
    title: {
      uk: "Лендінг агентства<br>інтернет-реклами<br>«Search Top»",
      ru: "Лендинг агентства<br>интернет-рекламы<br>«Search Top»",
    },
  },
  {
    image: "/home/img/thumb/kmir.webp",
    color: "white",
    title: {
      uk: "Сайт клубу<br />дитячого розвитку<br />«Конструктороманія»",
      ru: "Сайт клуба<br />детского развития<br />«Конструкторомания»",
    }
  },
  {
    image: "/home/img/thumb/gidro-dnepr.webp",
    color: "#fff700",
    title: {
      uk: "Сайт компанії<br />«Гідро-Дніпро»",
      ru: "Сайт компании<br />«Гидро-Днепр»",
    }
  },
  {
    image: "/home/img/thumb/polarini.webp",
    color: "black",
    title: {
      uk: "Сайт студії<br />штор та жалюзі<br />«Поларіні»",
      ru: "Сайт студии<br />штор и жалюзи<br />«Поларини»",
    }
  },
  {
    image: "/home/img/thumb/vprok.webp",
    color: "black",
    title: {
      uk: "Інтернет-магазин<br />спецій та прянощів<br />«Впрок»",
      ru: "Интернет-магазин<br />специй и пряностей<br />«Впрок»",
    }
  },
  {
    image: "/home/img/thumb/assistantasad.webp",
    color: "white",
    title: {
      uk: "Сайт Асоціації<br />адвокатів<br />Дніпропетровської області",
      ru: "Сайт Ассоциации<br />адвокатов<br />Днепропетровской области",
    }
  },
  {
    image: "/home/img/thumb/specteh-arenda.webp",
    color: "white",
    title: {
      uk: "Лендінг<br />«Оренда спецтехніки»",
      ru: "Лендинг<br />«Аренда спецтехники»",
    }
  },
  {
    image: "/home/img/thumb/bilyanochka-card.webp",
    color: "black",
    title: {
      uk: "Листівка<br />для кондитерської фабрики<br />«Біляночка»",
      ru: "Открытка<br />для кондитерской фабрики<br />«Биляночка»",
    }
  },
  {
    image: "/home/img/thumb/tnu.webp",
    color: "black",
    title: {
      uk: "Сайт Таврійського<br />національного<br />університету",
      ru: "Сайт Таврического<br />национального<br />университета",
    }
  },
  {
    image: "/home/img/thumb/endocrinologist.webp",
    color: "black",
    title: {
      uk: "Персональний сайт<br />лікаря-ендокринолога<br />Олесі Зініч",
      ru: "Персональный сайт<br />врача-эндокринолога<br />Олеси Зиныч",
    }
  },
  {
    image: "/home/img/thumb/luhansk-art-and-facts.webp",
    color: "black",
    title: {
      uk: "Сайт віртуального музею<br />культури та акціонізму<br />«Luhansk’sArt & Facts»",
      ru: "Сайт виртуального музея<br />культуры и акционизма<br />«Luhansk’sArt & Facts»",
    }
  },
  {
    image: "/home/img/thumb/makbeer-site.webp",
    color: "black",
    title: {
      uk: "Дизайн сайту<br />«Макарська пивоварня»",
      ru: "Дизайн сайта<br />«Макарская пивоварня»",
    }
  },
  {
    image: "/home/img/thumb/dolce-vita.webp",
    color: "#fcca77",
    title: {
      uk: "Сайт салону краси<br />«Дольче віта»",
      ru: "Сайт салона красоты<br />«Дольче вита»",
    }
  },
  {
    image: "/home/img/thumb/srkn.webp",
    color: "#359ee5",
    title: {
      uk: "Офіційний сайт<br />письменника<br />Володимира Сорокіна",
      ru: "Официальный сайт<br />писателя<br />Владимира Сорокина",
    }
  },
];


export default {
  name: "DesignBlock",
  setup() {
    const sliderImages = dataImages;
    return { sliderImages };
  },
  components: {
    Splide,
    SplideSlide,
    KinesisContainer,
    KinesisElement,
  },
};
</script>

<style lang="scss" scoped>
  .spiral {
    width: 480px;
  }
  
  // Mobile

  .portfolio-card__mobile {
    @apply relative;
    width: 300px;
    height: auto;
    @media (min-width: 768px) {
      width: 380px;
      height: 520px;
    }
  }
  .card-label__mobile {
    @apply flex flex-col justify-center items-center text-center bottom-4 md:bottom-12 left-0 absolute w-full px-6 h-44 text-2xl font-light;
    text-shadow: rgba(black, 0.5) 0 5px 7px;
  }

  // Псевдо-3D - увеличение центрального слайдера
  .splide__slide-container {
    transition: transform 400ms;
    transform: scale(0.9);
  }
  .splide__slide.is-active .splide__slide-container {
    transform: scale(1);
  }
  // Splide - arrows
  .splide__arrow.splide__arrow--prev {
    @apply left-0;
  }
  .splide__arrow.splide__arrow--next {
    @apply right-0;
  }

  // Desktop

  .portfolio-card {
    @apply relative flex flex-col mx-4 mt-2 mb-8 3xl:mb-12;
    //  3xl:mb-24 3xl:mb-20 mb-16
    width: 256px;
    height: 350px;
    @media (min-width: 1850px) {
      width: 380px;
      height: 520px;
    }
  }

  .card-label {
    @apply flex flex-col justify-center items-center text-center 3xl:bottom-4 3xl:bottom-16 bottom-3 left-0 absolute w-full px-6 h-44 text-xl 3xl:text-3xl font-light;
    text-shadow: rgba(black, 0.5) 0 5px 7px;
    transform: translate3d(0, 5px, 0) scale(0.8);
  }
  @media (min-width: 1536px) {
    .card-label {
      transform: translate3d(0, 15px, 0) scale(0.8);
    }
  }

  .portfolio-card:hover .card-label {
    text-shadow: rgba(black, 0.4) 0 8px 12px;
  }
  .card-img_wrapper {
    @apply group-hover:shadow-2xl absolute top-0 left-0 overflow-hidden shadow-xl;
  }
  .card-img {
    @apply group-hover:shadow-2xl;
    transition: all 0.2s ease-in-out;
  }

  // Стрелки слайдера, переставленные местами
  // Налево
  // .splide__arrows_reverse .splide__arrow.splide__arrow--next {
  //   @apply h-auto w-40 rounded-none left-0 top-auto bottom-1/2;
  //   background: linear-gradient(
  //     90deg,
  //     rgba(255, 255, 255, 1) 0%,
  //     rgba(255, 255, 255, 0) 100%
  //   );
  // }
  // Направо
  // .splide__arrows_reverse .splide__arrow.splide__arrow--prev {
  //   @apply h-auto w-40 rounded-none left-0 top-auto bottom-1/3;
  // }
  // .splide__arrow_label {
  //   @apply bg-picton-blue-900 hover:bg-red-700 ring-1 ring-white flex justify-center items-center  pt-1 text-sm font-light tracking-wider text-white transform rounded-full shadow-lg hover:shadow-xl uppercase;
  //   width: 70px;
  //   height: 70px;
  // }

  // .splide__arrows_reverse
  //   .splide__arrow.splide__arrow--prev
  //   .splide__arrow_label {
  //   bottom: 0;
  // }
  // .splide__arrows_reverse
  //   .splide__arrow.splide__arrow--next
  //   .splide__arrow_label {
  //   bottom: 50px;
  // }

  /* ********************************** */
  /* Splide  */
  /* ********************************** */

  .splide__pagination-design-desktop {
    padding-left: 4rem;
    @media (min-width: 1850px) {
      bottom: 30px !important;
    }
  }
  /* Тень под слайдами */
  .splide__slide {
    @apply flex justify-center items-center pt-2 pb-10 draggable;
  }
  .splide__slide-container {
    @apply shadow-xl block;
  }
  /* Стрелочки с затенением по бокам в светло-серый */
  // .splide__arrow-fade {
  //   @apply h-auto rounded-none m-0 w-8 sm:w-12 lg:w-16;
  //   &,
  //   &:hover {
  //     opacity: 1;
  //   }
  //   svg {
  //     @apply w-auto h-12 xl:h-16 fill-current text-picton-blue-900 hover:text-picton-blue-600;
  //     stroke: rgba(249, 250, 251, 1);
  //   }
  // }
  // .splide__arrow-fade--prev {
  //   @apply left-0 xl:pr-2 sm:pl-4 bg-transparent;
  //   svg {
  //     @apply ml-0;
  //   }
  // }
  // .splide__arrow-fade--next {
  //   @apply right-0 xl:pl-2 sm:pr-4 bg-transparent;
  //   svg {
  //     @apply mr-0;
  //   }
  // }
  // .fade-overlay {
  //   @apply h-full absolute top-0;
  // }
  // .fade-overlay__right {
  //   @apply right-0 bg-gradient-to-l;
  // }
  // .fade-overlay__left {
  //   @apply left-0 bg-gradient-to-r;
  // }
  /* Курсор для обозначения возможности таскать мышкой */
  .draggable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }
  }
  .purple-circle {
    @apply 3xl:text-3xl text-xl text-white bg-zina-purple rounded-full flex justify-center items-center;
    width: 250px;
    height: 250px;
    @media (min-width: 1680px) { 
      width: 377px;
      height: 377px;
    }
  }
  .portfolio-arrow {
    @apply inline-block mb-1.5 ml-2;
    width: 32px;
    @media (min-width: 1680px) { 
      width: 42px;
    }
  }
</style>

