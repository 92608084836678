<template>

  <form
    :id="formId"
    @submit="submitForm"
    method="post"
    action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSfkTL-D-I09BfQkm7he8dmuDoulOHCGMNWLYGgO1N2Eitcu4Q/formResponse"
    target="hidden_iframe"
    class="flex flex-col items-center max-w-screen-md text-xl"
  >
  <!-- Валидация формы -->
  
  <!-- <ul class="mb-8">
    <li>formEmpty: {{ formEmpty }}</li>
    <li>valueMessage: {{ valueMessage }}</li>
    <li>valueName: {{ valueName }}</li>
    <li>valueTel: {{ valueTel }}</li>
  </ul> -->
 

    <div class="textarea-wrapper">
      <div class="icon-col">
        <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-6">
          <path fill="currentColor" d="M12 24a12 12 0 1 1 9-4.2l2.7 2.7a.9.9 0 0 1-.6 1.4Zm5.1-16.2H6.8a.9.9 0 0 0 0 1.7h10.3a.9.9 0 0 0 0-1.7Zm0 3.4H6.8a.9.9 0 0 0 0 1.7h10.3a.9.9 0 0 0 0-1.7Zm.9 4.3a.9.9 0 0 0-.9-1H6.8a.9.9 0 0 0 0 1.8h10.3a.9.9 0 0 0 .9-.9Z" />
        </svg> -->
        <icon-base
          width="24"
          height="24"
          viewBox="0 0 24 24"
          class="w-6"
          :title="
            $i18n.locale === 'uk'
              ? 'Повідомлення'
              : 'Сообщение'
          "
        ><icon-message /></icon-base>
      </div>
      <div class="field-wrapper">
        <textarea
          v-model="state.message"
          name="entry.840774516"
          id="message"
          rows="4"
          class="field"
          :class="[
            $screen.landscape 
              ? '3xl:h-52 h-32' 
              : 'h-52'
          ]"
          minlength="20"
          placeholder=" "
        ></textarea>
        <label for="message" class="field-label">
          {{ $t('What site do you need') }}?
        </label>
        <div 
          v-if="valueMessage == false" 
          class="field-error"
        >
          {{ $t("Please write more") }}
        </div>
      </div>
    </div>

    <div class="lg:flex-row flex flex-col justify-between w-full">

      <div class="input-wrapper lg:pr-2">
        <div class="icon-col">
          <icon-base
            width="24"
            height="27"
            viewBox="0 0 24 26.8"
            class="w-6"
            :title="
              $i18n.locale === 'uk'
                ? 'Ваше ім\'я'
                : 'Ваше имя'
            "
          ><icon-user /></icon-base>
        </div>
        <div class="field-wrapper">
          <input
            v-model="state.name"
            type="text"
            name="entry.627908479"
            id="name"
            class="field"
            placeholder=" "
          />
          <label for="name" class="field-label">
            {{ $t("Your name") }}
          </label>
          <div 
            v-if="valueName == false" 
            class="field-error"
          >
            {{ $t("Please enter your name") }}
          </div>

        </div>
      </div>

      <div class="input-wrapper lg:pl-2">
        <div class="icon-col">
          <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-6">
            <path fill="currentColor" d="M13.4 24A13.4 13.4 0 0 1 0 10.6V4.5a1.2 1.2 0 0 1 1.2-1.2h4.9a1.2 1.2 0 0 1 1.2 1.2v4.9a1.2 1.2 0 0 1-1.2 1.2H4.9a8.4 8.4 0 0 0 8.5 8.5V18a1.2 1.2 0 0 1 1.2-1.2h4.9a1.2 1.2 0 0 1 1.2 1.2v4.9a1.2 1.2 0 0 1-1.2 1.2Zm-1.2-12.2V2.1h2.4v5.6L22.3 0 24 1.7l-7.7 7.7H22v2.4Z" />
          </svg> -->
          <icon-base
            width="24"
            height="24"
            viewBox="0 0 24 24"
            class="w-6"
            :title="
              $i18n.locale === 'uk'
                ? 'Ваш номер телефону'
                : 'Ваш номер телефона'
            "
          ><icon-tel /></icon-base>
        </div>
        <div class="field-wrapper">
          <input
            v-model="state.tel"
            v-mask="'+38 (###) ###-##-##'"
            id="phone"
            type="text"
            name="entry.1815934865"
            class="field"
            placeholder=" "
          />
          <label for="phone" class="field-label">
            {{ $t("Your phone") }}
          </label>
          <div 
            v-if="valueTel == false" 
            class="field-error"
          >
            {{ $t("Please enter your phone number") }}
          </div>
        </div>
      </div>
    </div>

    <p class="3xl:text-xl 3xl:mb-5 mb-3 text-lg text-center">
      {{ $t('Preferred messenger') }}:
    </p>
    
    <div class="radio-messengers land:justify-start 3xl:mb-10 flex flex-wrap items-center justify-center mb-6">
      <span>
        <input type="radio" name="entry.1107316697" id="telegram" value="Telegram" />
        <label for="telegram" class="radio-label radio-telegram">
          <span>Telegram</span>
        </label>
      </span>

      <span>
        <input type="radio" name="entry.1107316697" id="viber" value="Viber" />
        <label for="viber" class="radio-label radio-viber">
          <span>Viber</span>
        </label>
      </span>

      <span>
        <input type="radio" name="entry.1107316697" id="whatsapp" value="WhatsApp" />
        <label for="whatsapp" class="radio-label radio-whatsapp">
          <span>WhatsApp</span>
        </label>
      </span>

    </div>
    <!-- Скрытые поля: информация о форме -->
    <!-- formDescription -->
    <input
      type="hidden"
      name="entry.1443531788"
      :value="formDescription"
    />
    <!-- formCta -->
    <input
      type="hidden"
      name="entry.1680781774"
      :value="formCta"
    />
    <!-- Вычисляемые скрытые поля -->
    <!-- Lang -->
    <input
      type="hidden"
      name="entry.1205808099"
      :value="$i18n.locale"
    />
    <!-- pageTitle -->
    <input
      type="hidden"
      name="entry.1379265897"
      :value="pageTitle"
    />
    <!-- currentUrl -->
    <input
      type="hidden"
      name="entry.634569047"
      :value="currentUrl"
    />
    <!-- deviceScreen -->
    <input
      type="hidden"
      name="entry.744347624"
      :value="deviceScreenWidth+'×'+deviceScreenHeight+' ('+$screen.resolution+'), '+$screen.orientation+', touch: '+$screen.touch"
    />
    <!-- Геоданные, получаемые по IP через сторонний сервис -->
    <!-- userIP -->
    <input
      type="hidden"
      name="entry.2090646003"
      :value="userIP"
    />
    <!-- userCity -->
    <input
      type="hidden"
      name="entry.2113741160"
      :value="userCity"
    />
    <!-- userBrowser -->
    <input
      type="hidden"
      name="entry.2127173447"
      :value="userBrowser"
    />
    <!-- userDevice -->
    <input
      type="hidden"
      name="entry.1120544007"
      :value="userDevice"
    />
    
    <div class="submit-wrapper relative">
      <button
        type="submit"
        class=" hover:bg-zina-purple hover:shadow-lg bg-zina-purple 3xl:px-20 3xl:py-4 3xl:text-2xl px-16 py-3 text-xl tracking-wide text-center text-white uppercase rounded-full"
      >
        <span v-if="submitStatus != 'PENDING'">
          {{ $t('Send') }}
        </span>
        <span 
          class="flex items-end justify-end" 
          v-if="submitStatus == 'PENDING'"
        >
          {{ $t('Sending') }}<div class="dot-flashing"></div>
        </span>
      </button>

      <div 
        v-if="formEmpty == true" 
        class="-bottom-10 left-1/2 whitespace-nowrap absolute text-lg text-red-700"
        style="transform: translate(-50%);"
      >
        {{ $t("Please fill out the form") }}.
      </div>
    </div>

    <!-- <ul>
      <li>lang: {{ $i18n.locale }}</li>
      <li>userIP: {{ userIP }}</li>
      <li>userCity: {{ userCity }}</li>
      <li>submitted: "{{ submitted }}"</li>
    </ul> -->

  </form>

  <!-- Скрытый iframe для пересылки Google form -->
  <!-- <ul>
    <li>$i18n.locale: "{{ $i18n.locale }}" </li>
  </ul> -->
  <iframe 
    v-if="submitted"
    name="hidden_iframe" 
    id="hidden_iframe" 
    style="display: none;"
    @load="goToThankyou($i18n.locale)"
  ></iframe>

</template>
<script>

import useValidate from "@vuelidate/core";
import { required, minLength, maxLength } from "@vuelidate/validators";
import { reactive, computed } from "vue";
import { defineAsyncComponent } from "vue";
// const FormHiddenFrame = defineAsyncComponent(() => import("@/components/UI/FormHiddenFrame"));
const IconBase = defineAsyncComponent(() => import("@/components/IconBase"));
const IconMessage = defineAsyncComponent(() => import("@/components/icons/IconMessage"));
const IconUser = defineAsyncComponent(() => import("@/components/icons/IconUser"));
const IconTel = defineAsyncComponent(() => import("@/components/icons/IconTel"));
// Получение геоданных через https://ipregistry.co/
const {IpregistryClient} = require('@ipregistry/client');
const client = new IpregistryClient('rsg4ehxbfty93r08');

export default {
  name: "form-order",
  components: {
    IconBase,
    IconMessage,
    IconUser,
    IconTel,
    // FormHiddenFrame
  },
  props: {
    formId: {
      type: String,
    },
    formDescription: {
      type: String,
    },
    formCta: {
      type: String,
    },
    productName: {
      type: String,
      default: ''
    },
    price: {
      type: Number,
      default: null
    },
    currency: {
      type: String,
      default: ''
    }
  },
  setup() {
    const state = reactive({
      name: "",
      tel: "",
      message: "",
      formEmpty: "",
    });

    const rules = computed(() => {
      return {
        name: { required },
        tel: { required, minLength: minLength(12), maxLength: maxLength(12) },
        message: { required },
      };
    });

    const v$ = useValidate(rules, state);

    return {
      state,
      v$,
    };
  },

  data() {
    return {
      valueName: null,
      valueTel: null,
      valueMessage: null,
      "state.name": this.value,
      "state.tel": this.value,
      "state.message": this.value,
      formEmpty: null,
      submitted: false,
      pageTitle: "",
      deviceScreenWidth: screen.width,
      deviceScreenHeight: screen.height,
      currentUrl: window.location.href,
      userIP: "",
      userCity: "",
      userBrowser: "",
      userDevice: "",
    };
  },

  watch: {
    "state.name"(currentValue) {
      this.$emit("input", currentValue);
      if (currentValue.length > 3) {
        this.valueName = true;
        this.formEmpty = false;
      } else {
        this.valueName = false;
      }
    },
    "state.tel"(currentValue) {
      this.$emit("input", currentValue);
      if (currentValue.toString().length == 19) {
        this.valueTel = true;
        this.formEmpty = false;
      } else {
        this.valueTel = false;
      }
    },
    "state.message"(currentValue) {
      this.$emit("input", currentValue);
      if (currentValue.length > 19) {
        this.valueMessage = true;
        this.formEmpty = false;
      } else {
        this.valueMessage = false;
        this.formEmpty = false;
      }
    },
  },

  methods: {
    submitForm(e) {
      this.pageTitle = this.$t('title');
      // console.log('price: '+this.price);
      // console.log('currency: '+this.currency);
      // console.log('productName: '+this.productName);
      console.log('formCta: '+this.formCta);
      
      // По сабмиту добавляем в форму геоданные
      // ToDo: Instead of using promises, you can also use async/await 
      // - https://github.com/ipregistry/ipregistry-javascript
      if (this.userIP === "") { // Дергаем геосервис только когда данных нету
        client.lookup().then(response => {
          this.userIP = response.data.ip;
          this.userCity = response.data.location.city + ', ' + response.data.location.country.name + ' ' + response.data.location.country.flag.emoji;
          this.userBrowser = response.data.user_agent.name + ' ' + response.data.user_agent.version_major + ', ' + response.data.user_agent.os.name + ' ' + response.data.user_agent.os.version;
          this.userDevice = response.data.user_agent.device.type + ' — ' + response.data.user_agent.device.name;
        }).catch(error => {
          console.log(error);
        })
      }
      // Валидация
      if ( this.valueName === true && this.valueTel === true && this.valueMessage === true ) {
        // Покажем фейковую, но успокаивающую анимацию отправки
        this.submitStatus = 'PENDING'
        // console.log('submitStatus: '+this.submitStatus)
        // Отправим в GTM
        this.$gtm.trackEvent({
          event: 'orderSite',
          // label: '',
          value: "1160",
          currency: "USD",
          product_name: this.formCta,
          noninteraction: false,
        });
        this.submitted = true;
        return true;
      } else {
        this.submitted = false;
        this.formEmpty = true;
        if (this.valueMessage === null ) {
          this.valueMessage = false;
        }
        if (this.valueName === null ) {
          this.valueName = false;
        }
        if (this.valueTel === null ) {
          this.valueTel = false;
        }
        e.preventDefault();
      }
    },
    goToThankyou(lang) {
      setTimeout(() => { 
        // Переход на Thank You Page
        window.location='/'+lang+'/thankyou/';
      }, 1000)
    }
  },
};
</script>

<style scoped lang="scss">

  .icon-col {
    @apply w-8 pt-5;
  }
  .textarea-wrapper {
    @apply flex w-full mb-12;
  }
  .input-wrapper {
    @apply lg:w-1/2 flex mb-10;
  }
  .field-wrapper {
    @apply relative flex flex-col flex-grow;
  }
  .field {
    @apply border-current pt-4 pb-5 pl-6 pr-4 text-xl border-2 bg-transparent;
  }
  .field-label {
    @apply absolute cursor-text pointer-events-none;
    top: 18px;
    left: 25px;
    transition: transform 200ms;
  }
  .field:focus + .field-label,
  .field[placeholder]:not(:placeholder-shown) + .field-label {
    @apply text-base;
    transform: translateY(-44px);
    left: 26px;
  }
  .field-error {
    @apply -bottom-6 left-1/2 absolute text-sm text-center bg-red-100 text-red-700 px-3 pb-1 whitespace-nowrap rounded-b-lg;
    transform: translate(-50%);
  }

  // Radio Buttons
  
  .radio-label {
    @apply cursor-pointer flex justify-center items-center 3xl:w-44 w-32 3xl:h-12 h-9 rounded-full px-3 sm:px-5 pt-1 pb-1 mx-2 mb-3 border-2 border-current bg-current;
    span {
      @apply 3xl:text-xl text-base text-white;
    }
  }
  .radio-telegram:hover {
    @apply border-picton-blue-500 bg-picton-blue-500;
  }
  .radio-viber:hover {
    @apply border-zina-purple bg-zina-purple;
  }
  .radio-whatsapp:hover {
    border: 1px solid #6CCB69;
    background: #6CCB69;
  }
  .radio-messengers {
    input[type="radio"] {
      display: none;
      &:checked + label {
        @apply border-white;
      }
      &:checked + label.radio-telegram {
        @apply bg-picton-blue-500 ;
      }
      &:checked + label.radio-viber {
        @apply bg-zina-purple;
      }
      &:checked + label.radio-whatsapp {
        background: #6CCB69;
      }
    }
  }

 /**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
 .dot-flashing {
  position: relative;
  animation-delay: .5s !important;
  animation: dotFlashing 1s infinite linear alternate;
  margin-left: 12px;
  margin-bottom: 6px;
}
.dot-flashing,
.dot-flashing::before,
.dot-flashing::after {
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
  background-color: #9880ff;
  color: #9880ff;
}
.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  animation: dotFlashing 1s infinite alternate;
}
.dot-flashing::before {
  left: -9px;
  animation-delay: 0s !important;
}
.dot-flashing::after {
  left: 9px;
  animation-delay: 1s !important;
}
@keyframes dotFlashing {
  0% {
    background-color: #9880ff;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}
</style>
