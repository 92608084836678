<template>
  <g fill="currentColor" transform="translate(16.9 -4.4)">
    <circle cx="7.5" cy="7.5" r="7.5" transform="translate(14.5 50.3)"/>
    <path d="M46.2 50.2a7.6 7.6 0 1 0 6.9 6.9 7.6 7.6 0 0 0-6.9-6.9Z" />
    <path d="M61.8 21.5a2 2 0 0 0-1.7-.9h-8.4v-.1a16 16 0 1 0-32 0v.1h-6.3L11.3 11a2.2 2.2 0 0 0-2.1-1.6H0v4.4h7.4L14.2 45a2.1 2.1 0 0 0 2.1 1.7h38.4a2.2 2.2 0 0 0 2-1.7l5.5-21.7a2 2 0 0 0-.4-1.8ZM35.7 32.1a11.7 11.7 0 1 1 11.6-11.6A11.7 11.7 0 0 1 35.7 32Z" />
    <path d="m34 20.7-4.1-4.1-3.2 3 5.6 5.7a2.2 2.2 0 0 0 1.6.7 2 2 0 0 0 1.5-.6l9.2-8.7-3-3.3Z" />
    <path d="M-16.9 53.4h12v12h-12z" />
    <path d="M72.1 53.4h12v12h-12z" />
  </g>
</template>
