<template>
  <div 
    v-if="$screen.landscape"
    class="absolute bottom-0 flex justify-center"
  >
    <button class="border-picton-blue-900 text-picton-blue-900 hover:text-white hover:bg-picton-blue-900 hover:shadow-lg focus:outline-none 2xl:text-xl 2xl:mb-10 2xl:pt-1.5 2xl:pb-1 2xl:pl-7 2xl:pr-4 3xl:text-2xl  3xl:pt-2 3xl:pb-2 3xl:pl-9 3xl:pr-6 pt-1 pb-0.5 pl-6 pr-4 mb-5 text-lg uppercase border rounded-full">
      <a 
        class="flex" 
        href="#" 
        v-scroll-to="nextAnchor"
      >
        <slot></slot>
        <svg width="30px" height="30px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"
          class="bi bi-arrow-down section-next__arrow 2xl:ml-2 3xl:w-7 3xl:mt-0.5 w-5 ml-1"
          fill="currentColor"
        >
          <path fill-rule="evenodd" d="M4.646 9.646a.5.5 0 0 1 .708 0L8 12.293l2.646-2.647a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 0-.708z" />
          <path fill-rule="evenodd" d="M8 2.5a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-1 0V3a.5.5 0 0 1 .5-.5z" />
        </svg>
      </a>
    </button>
    </div>
</template>

<script>
  export default {
    name: "next-section-button",
    props: {
      nextAnchor: {
        type: String,
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
