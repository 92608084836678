<template>
  <!-- Блоки главной страницы -->
    <section
      id="home"
      class="bg-picton-blue-900 bg-gradient-to-b from-picton-blue-900 land:via-picton-blue-900 to-picton-blue-500 lg:pt-16 xl:pt-20 2xl:pt-24 relative flex flex-col items-center justify-center h-screen overflow-hidden text-white"
    >
      <!-- Событие открытой модалки -->
      <ParallaxBlock @modal="Modal" />
      <custom-anchor-button
        v-if="$screen.landscape"
        :nextAnchor="'#technology'" 
      />
    </section>

    <!-- <TechnologyBlock /> -->
    <section
      id="technology"
      class="fullpage-new"
    >
      <TechnologyBlock />
      <button-next-section :nextAnchor="'#design'">
        <span v-if="$i18n.locale === 'uk'">
          Чудовий дизайн
        </span>
        <span v-if="$i18n.locale === 'ru'">
          Впечатляющий дизайн
        </span>
      </button-next-section>
    </section>

    <section 
      id="design"
      class="fullpage-new"
    >
      <DesignBlock />
      <button-next-section :nextAnchor="'#full-service'">
        <span v-if="$i18n.locale === 'uk'">
          Реально під ключ
        </span>
        <span v-if="$i18n.locale === 'ru'">
          Реально под ключ
        </span>
      </button-next-section>
    </section>

    <section 
      id="full-service"
      class="fullpage-new"
    >
      <FullServiceBlock />
      <button-next-section-yellow :nextAnchor="'#experience'">
        <span v-if="$i18n.locale === 'uk'">
          Плавали — знаємо
        </span>
        <span v-if="$i18n.locale === 'ru'">
          Плавали — знаем
        </span>
      </button-next-section-yellow>
    </section>

    <section 
      id="experience"
      class="fullpage-new"
    >
      <ExperienceBlock />
      <button-next-section :nextAnchor="'#prices'">
        <span v-if="$i18n.locale === 'uk'">
          Прийнятні ціни
        </span>
        <span v-if="$i18n.locale === 'ru'">
          Норм цены
        </span>
      </button-next-section>
    </section>
    
    <section 
      id="prices"
      class="fullpage-new"
    >
      <PricesBlock />
      <button-next-section :nextAnchor="'#awards'">
        <span v-if="$i18n.locale === 'uk'">
          Нагороди
        </span>
        <span v-if="$i18n.locale === 'ru'">
          Награды
        </span>
      </button-next-section>
    </section>

    <section
      id="awards"
      class="fullpage-new"
    >
      <AwardsBlock />
      <button-next-section :nextAnchor="'#trust'">
        <span v-if="$i18n.locale === 'uk'">
          Довіртеся нам
        </span>
        <span v-if="$i18n.locale === 'ru'">
          Доверьтесь нам
        </span>
      </button-next-section>
    </section>

    <section 
      id="trust"
      class="fullpage-new"
    >
      <TrustBlock />
      <button-next-section :nextAnchor="'#feedback'">
        <span v-if="$i18n.locale === 'uk'">
          Люди кажуть
        </span>
        <span v-if="$i18n.locale === 'ru'">
          Люди говорят
        </span>
      </button-next-section>
    </section>

    <section 
      id="feedback"
      class="fullpage-new"
    >
      <FeedBackBlock />
      <button-next-section :nextAnchor="'#office'">
        <span v-if="$i18n.locale === 'uk'">
          Ми поруч
        </span>
        <span v-if="$i18n.locale === 'ru'">
          Мы близко
        </span>
      </button-next-section>
    </section>
    
    <section 
      id="office"
      class="fullpage-new"
    >
      <OfficeBlock />
      <button-next-section :nextAnchor="'#order'">
        <span v-if="$i18n.locale === 'uk'">
          На зв'язку
        </span>
        <span v-if="$i18n.locale === 'ru'">
          На связи
        </span>
      </button-next-section>
    </section>

    <section 
      id="order"
      class=""
    >
      <OrderBlock />
    </section>

  <!-- Footer -->
  <section class="h-auto" id="footer">
    <Footer />
  </section>

  <!-- Боковая якорная навигация -->
  <div 
    v-if="$screen.landscape"
    class="land:block right-4 top-1/2 fixed z-10 hidden"
    style="transform: translateY(-50%);"
  >
    <span
      class="menu-point"
      :class="[
        { active: activeSection == index }, 
        activeSection == '0' ? 'bg-picton-blue-300' :  'bg-picton-blue-900'
      ]"
      v-for="(offset, index) in offsets"
      :key="index"
      @click="scrollToSection(index)"
    />
  </div>

  
  <!-- Блок для отладки верстки -->
  <!-- <DevViewports /> -->
</template>

<script>
import { useMeta } from "vue-meta";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { mapGetters, mapMutations } from "vuex";
// Асинхронная загрузка компонента, позволяет отделить этот кусок от основного кода js (В принципе подключать лучше всегда так при возможности)
import { defineAsyncComponent } from "vue";

const ParallaxBlock = defineAsyncComponent(() => import("@/components/Blocks/ParallaxBlock"));
const TechnologyBlock = defineAsyncComponent(() => import("@/components/Blocks/TechnologyBlock"));
const DesignBlock = defineAsyncComponent(() => import("@/components/Blocks/DesignBlock"));
const FullServiceBlock = defineAsyncComponent(() => import("@/components/Blocks/FullServiceBlock"));
const ExperienceBlock = defineAsyncComponent(() => import("@/components/Blocks/ExperienceBlock"));
const PricesBlock = defineAsyncComponent(() => import("@/components/Blocks/PricesBlock"));
const AwardsBlock = defineAsyncComponent(() => import("@/components/Blocks/AwardsBlock"));
const TrustBlock = defineAsyncComponent(() => import("@/components/Blocks/TrustBlock"));
const FeedBackBlock = defineAsyncComponent(() => import("@/components/Blocks/FeedBackBlock"));
const OfficeBlock = defineAsyncComponent(() => import("@/components/Blocks/OfficeBlock"));
const OrderBlock = defineAsyncComponent(() => import("@/components/Blocks/OrderBlock"));
const Footer = defineAsyncComponent(() => import("@/components/Footer"));
// const DevViewports = defineAsyncComponent(() => import("@/components/Dev/DevViewports"));

export default defineComponent({
  name: "Home",
  setup () {
    const {t} = useI18n();
    useMeta({
      title: t('title'),
      meta: [
        { 
          vmid: 'description', 
          property: 'description', 
          content: t('description')
        },
        { 
          vmid: 'og:title', 
          property: 'og:title', 
          content: t('title')
        },
        { 
          vmid: 'og:description', 
          property: 'og:description', 
          content: t('description')
        },
      ],
    })
    return {
      t,
    };
  },
  components: {
    ParallaxBlock,
    TechnologyBlock,
    DesignBlock,
    FullServiceBlock,
    ExperienceBlock,
    PricesBlock,
    AwardsBlock,
    TrustBlock,
    FeedBackBlock,
    OfficeBlock,
    OrderBlock,
    Footer,
    // DevViewports
  },
  computed: {
    ...mapGetters({
      isButtonActive: "modal/modalVisible",
    }),
  },
  data() {
    return {
      inMove: false,
      activeSection: 0,
      offsets: [],
      touchStartY: 0,
      popupVisible: false,
      desktop: true,
      mobile: false,
      window: {
        width: 0,
      },
    };
  },

  methods: {
    ...mapMutations({
      setActive: "section/setActive",
    }),
    calculateSectionOffsets() {
      let sections = document.getElementsByTagName("section");
      let length = sections.length;

      for (let i = 0; i < length; i++) {
        let sectionOffset = sections[i].offsetTop;
        this.offsets.push(sectionOffset);
      }
    },
    handleMouseWheel: function (e) {
      // Скроллинг только если разрешен
      if (!this.isButtonActive) {
        if (e.wheelDelta < 30 && !this.inMove) {
          this.moveUp();
        } else if (e.wheelDelta > 30 && !this.inMove) {
          this.moveDown();
        }
        e.preventDefault();
        return false;
      }
    },
    handleMouseWheelDOM: function (e) {
      if (e.detail > 0 && !this.inMove) {
        this.moveUp();
      } else if (e.detail < 0 && !this.inMove) {
        this.moveDown();
      }

      return false;
    },
    pageUp() {
      this.inMove = true;
      this.activeSection = 0;
      this.scrollToSection(this.activeSection, true);
      this.setPageScrollHistoryHash("#section" + this.activeSection);
    },
    pageDown() {
      this.inMove = true;
      this.activeSection = this.offsets.length - 1;
      this.scrollToSection(this.activeSection, true);
      this.setPageScrollHistoryHash("#section" + this.activeSection);
    },
    moveDown() {
      this.inMove = true;
      this.activeSection--;

      if (this.activeSection < 0) this.activeSection = 0;

      this.scrollToSection(this.activeSection, true);
      this.setPageScrollHistoryHash("#section" + this.activeSection);
    },
    moveUp() {
      this.inMove = true;
      this.activeSection++;

      if (this.activeSection > this.offsets.length - 1)
        this.activeSection = this.offsets.length - 1;

      this.scrollToSection(this.activeSection, true);
      this.setPageScrollHistoryHash("#section" + this.activeSection);
    },
    scrollToSection(id, force = false) {
      this.setActive(id);
      if (this.inMove && !force) return false;

      this.activeSection = id;
      this.inMove = true;

      //Добавкляем или убираем класс у секции к которой прокрутились.
      document.getElementsByTagName("section").forEach(function (e, index) {
        if (index === id) {
          e.classList.add("active");
        } else {
          e.classList.remove("active");
        }
      });

      document.getElementsByTagName("section")[id].scrollIntoView({
        behavior: "smooth",
      });

      setTimeout(() => {
        this.inMove = false;
      }, 400);
    },
    touchStart(e) {
      e.preventDefault();

      this.touchStartY = e.touches[0].clientY;
    },
    touchMove(e) {
      if (this.inMove) return false;
      e.preventDefault();

      let currentY = e.touches[0].clientY;

      if (this.touchStartY < currentY) {
        this.moveDown();
      } else {
        this.moveUp();
      }

      this.touchStartY = 0;
      return false;
    },
    keydown(e) {
      switch (e.code) {
        case "ArrowUp":
        case "PageUp":
          e.preventDefault();
          this.moveDown();
          break;
        case "ArrowDown":
        case "PageDown":
          e.preventDefault();
          this.moveUp();
          break;
        case "Home":
          e.preventDefault();
          this.pageUp();
          break;
        case "End":
          e.preventDefault();
          this.pageDown();
          break;
      }
    },
    firstScroll() {
      let sectionId = this.getSectionHashId();
      if (sectionId !== false) {
        if (this.offsets.length >= sectionId) {
          this.scrollToSection(sectionId, true);
        }
      }
    },
    getSectionHashId() {
      let hash = document.location.hash;
      if (hash != "") {
        let regex = /\d+/g;
        let sectionId = hash.match(regex);
        if (sectionId != -1 && sectionId[0] != null) {
          return sectionId[0];
        }
      }
      return false;
    },
    setClass(id) {
      // console.log(id);
      if (id > 0) {
        document.querySelectorAll(".navbar__wrapper").forEach(function (e) {
          e.classList.add("z-navbar__wrapper_thin_inject");
        });
      } else {
        document.querySelectorAll(".navbar__wrapper").forEach(function (e) {
          e.classList.remove("z-navbar__wrapper_thin_inject");
        });
      }
    },
    setPageScrollHistoryHash(hash) {
      window.history.pushState(hash, "", hash);
      this.setClass(this.getSectionHashId());
    },

    pageScrollByButtons() {
      let section__next_button = document.querySelectorAll(
        ".section__next-button"
      );
      let thisAfter = this;

      section__next_button.forEach((element) => {
        element.addEventListener("click", function (event) {
          thisAfter.setPageScrollHistoryHash(element.href);

          event.preventDefault();
          let sectionId = thisAfter.getSectionHashId();
          thisAfter.scrollToSection(sectionId, true);
        });
      });
    },
    handleResize() {
      this.window.width = window.innerWidth;

      if (this.window.width >= 1280) {
        (this.desktop = true), (this.mobile = false);
      } else if (this.window.width < 1280) {
        (this.desktop = false), (this.mobile = true);
      }
    },
    scrollToSectionSetHash(index) {
      this.scrollToSection(index);
      this.setPageScrollHistoryHash("#section" + index);
    },
    updateSectionHash(e) {
      let scrollY = window.pageYOffset;
      let thisParent = this;
      if (e.code == "Space" || e.button == "0") {
        this.offsets.forEach(function (currentValue, key) {
          if (null != thisParent.offsets[key - 1]) {
            if (
              scrollY <= currentValue &&
              scrollY > thisParent.offsets[key - 1]
            ) {
              thisParent.setPageScrollHistoryHash("#section" + (key - 1));
              thisParent.activeSection = key - 1;
              return true;
            }
          } else if (scrollY >= 0 && scrollY <= thisParent.offsets[1]) {
            thisParent.setPageScrollHistoryHash("#section" + 0);
            thisParent.activeSection = 0;
            return true;
          }
        });
      }
    },
    showPopup() {
      this.popupVisible = true;
    },
    // goToThankyou(lang) {
    //   if (this.formSubmitted === true) {
    //     window.location='/'+lang+'/thankyou/';
    //   }
    // },
    // checkSubmittedStatus (value) {
    //   console.log('Form Submitted: '+value);
    //   this.formSubmitted = value;
    // } 
  },

  // created() {
  //   // window.addEventListener("resize", this.handleResize);
  //   this.calculateSectionOffsets();
  //   this.handleResize();
  // },

  // destroy() {
  //   window.removeEventListener("resize", this.handleResize);
  // },
  watch: {
    //Следим за значением. Если true, отключаем скролл на body
    isButtonActive(val) {
      if (val === true) {
        let body = document.getElementsByTagName("body")[0];
        body.style.overflow = "hidden";
      }
    },
    mobile(val) {
      if (val === true) {
        let body = document.getElementsByTagName("body")[0];
        body.style.overflow = "auto";
        window.removeEventListener("mousewheel", this.handleMouseWheel, {
          passive: false,
        }); // Other browsers
        window.removeEventListener("keydown", this.handleKey, {
          passive: false,
        }); // Other browsers
        window.removeEventListener("DOMMouseScroll", this.handleMouseWheelDOM); // Mozilla Firefox
        window.removeEventListener("touchstart", this.touchStart); // mobile devices
        window.removeEventListener("touchmove", this.touchMove); // mobile devices
      } else {
        window.addEventListener("DOMMouseScroll", this.handleMouseWheelDOM); // Mozilla Firefox
        window.addEventListener("mousewheel", this.handleMouseWheel, {
          passive: false,
        }); // Other browsers
        this.pageScrollByButtons();
        window.addEventListener("keyup", this.updateSectionHash, {
          passive: false,
        });
        window.addEventListener("mouseup", this.updateSectionHash, {
          passive: false,
        });
        window.addEventListener("keydown", this.keydown);
      }
    },
  },
  mounted() {
    this.calculateSectionOffsets();
    this.handleResize();
    window.addEventListener("resize", this.handleResize);

    window.addEventListener("DOMMouseScroll", this.handleMouseWheelDOM); // Mozilla Firefox
    window.addEventListener("mousewheel", this.handleMouseWheel, {
      passive: false,
    }); // Other browsers
    this.pageScrollByButtons();
    window.addEventListener("keyup", this.updateSectionHash, {
      passive: false,
    });
    window.addEventListener("mouseup", this.updateSectionHash, {
      passive: false,
    });
    window.addEventListener("keydown", this.keydown);
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
    // Удаляем события
    window.removeEventListener("mousewheel", this.handleMouseWheel, {
      passive: false,
    }); // Other browsers
    window.removeEventListener("keydown", this.handleKey, {
      passive: false,
    }); // Other browsers
    window.removeEventListener("DOMMouseScroll", this.handleMouseWheelDOM); // Mozilla Firefox
    window.removeEventListener("touchstart", this.touchStart); // mobile devices
    window.removeEventListener("touchmove", this.touchMove); // mobile devices
  },
});
</script>

<style>
  body {
    overflow: hidden;
  }
  .fullpage {
    @apply flex flex-col items-center justify-center land:h-screen relative w-full pt-12 xl:pt-14;
  }
  .fullpage-new {
    @apply land:h-screen xl:pt-14 relative w-full land:pt-12 overflow-hidden;
  }
  .menu-point {
    @apply w-3 h-3 rounded-full block my-5;
    transition: 0.4s ease all;
  } 
  .menu-point.active {
    @apply ring-2;
    transform: scale(1.4);
  }

</style>
