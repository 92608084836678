export const selectModule = {

    state() {
        return {
            isBurgerActive: false,
            ifScroll: false
        }
    },

    getters: {
        selectIsBurgerActive(state) {
            return state.isBurgerActive;
        },
        ifScroll(state) {
            return state.ifScroll;
        }
    },

    mutations: {
        setIsBurgerActive(state, data) {
            state.isBurgerActive = data;
        },
        setIfScroll(state, data) {
            state.ifScroll = data;
        }
    },

    actions: {

    },

    namespaced: true,

};