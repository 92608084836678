<template>
  <div id="navbar" class="relative z-20">
    <div
      class=" transform-all md:px-2 fixed w-screen h-10 pr-1 duration-300 ease-in-out bg-white shadow-lg"
      :class="[ifScroll ? 'md:h-12 xl:h-14' : 'md:h-14 xl:h-20 2xl:h-24']"
    >
      <div class=" lg:pl-4 lg:pr-16 2xl:pr-20 3xl:pr-24 container relative flex items-center justify-between h-full">
        <localized-link
          :to="{ name: 'Home' }"
          class="land:left-8 md:left-2 absolute left-0"
          :class="[isBurgerActive && !modalVisible ? 'z-40' : '']"
        >
          <!-- Позиция активной секции, class: "active"-->
          <!-- {{ active }} -->
          <!-- <img
            :src="
              $i18n.locale === 'uk'
                ? '/home/img/zina-logo-uk.svg'
                : '/home/img/zina-logo-ru.svg'
            "
            rel="preload"
            width="261"
            height="48"
            :alt="
              $i18n.locale === 'uk'
                ? 'Студія «Зіна дизайн»'
                : 'Студия «Зина дизайн»'
            "
            class="w-auto duration-500 ease-in-out transform"
            :class="[
              ifScroll ? 'h-10 md:h-9 xl:h-10' : 'h-10 xl:h-12',
              isBurgerActive ? '' : '',
            ]"
          /> -->
          <icon-base
            width="261"
            height="48"
            viewBox="0 0 236.5 43.5"
            class="w-auto duration-500 ease-in-out transform"
            :class="[
              ifScroll ? 'h-10 md:h-9 xl:h-10' : 'h-10 xl:h-12',
              isBurgerActive ? '' : '',
            ]"
            style="color: #ed2027;"
            :title="
              $i18n.locale === 'uk'
                ? 'Студія «Зіна дизайн»'
                : 'Студия «Зина дизайн»'
            "
            :desc="
              $i18n.locale === 'uk'
                ? 'Веб-студія у Дніпрі: розробка сайтів, лендингів, інтернет-магазинів'
                : 'Веб-студия в Днепре: разработка сайтов, лендингов, интернет-магазинов'
            "
          ><icon-zina-logo /></icon-base>
        </localized-link>
        <div style="min-width: 261px" class="w-2/12"></div>
        <div
          class=" main-menu md:flex xl:space-x-10 lg:space-x-8 lg:w-5/12 lg:ml-8 lg:mr-4 xl:mr-0 items-center justify-center hidden w-8/12 h-12 mr-16 space-x-6 duration-200 ease-in-out transform border-blue-500"
          :class="[ifScroll ? 'text-lg xl:text-xl' : 'text-xl xl:text-2xl']"
        >
          <a
            class="main-menu__link whitespace-nowrap duration-200 transform"
            :class="[ifScroll ? 'hover:mb-1.5' : 'hover:mb-2.5']"
            :href="$i18n.locale === 'uk' ? '/uk/about/' : '/ru/about/'"
            :aria-label="$t('About')"
            >{{ $t("About") }}</a
          >
          <a
            class="main-menu__link duration-200 transform"
            :class="[ifScroll ? 'hover:mb-1.5' : 'hover:mb-2.5']"
            :href="$i18n.locale === 'uk' ? '/uk/portfolio/' : '/ru/portfolio/'"
            :aria-label="$t('Portfolio')"
            >{{ $t("Portfolio") }}</a
          >
          <a
            class="main-menu__link duration-200 transform"
            :class="[ifScroll ? 'hover:mb-1.5' : 'hover:mb-2.5']"
            :href="$i18n.locale === 'uk' ? '/uk/services/' : '/ru/services/'"
            :aria-label="$t('Services')"
            >{{ $t("Services") }}</a
          >
          <a
            class="main-menu__link 2xl:block lg:hidden block duration-200 transform"
            :class="[ifScroll ? 'hover:mb-1.5' : 'hover:mb-2.5']"
            :href="$i18n.locale === 'uk' ? '/uk/price/' : '/ru/price/'"
            :aria-label="$t('Prices')"
            >{{ $t("Prices") }}</a
          >
          <!-- <a
            class="main-menu__link duration-200 transform"
            :class="[ifScroll ? 'hover:mb-1.5' : 'hover:mb-2.5']"
            :href="$i18n.locale === 'uk' ? '/contacts/' : '/ru/contacts/'"
            :aria-label="$t('Contacts')"
            >{{ $t("Contacts") }}</a
          > -->
        </div>
        
        <!-- Кнопка "рука" со списком мессенджеров -->
        <div class="phone-order lg:flex xl:w-3/12 lg:w-4/12 relative items-center justify-center hidden">
          <button-messengers></button-messengers>
        </div>

        <div class="xl:flex justify-center hidden w-1/12 border-red-500">
          <transition name="lang">
            <language-switcher
              v-slot="{ links }"
              tag="ul"
              class="lang md:flex hidden duration-200"
              active-class="text-white bg-gray-500"
              :class="[
                isBurgerActive && !modalVisible ? 'flex md:flex z-40' : '',
              ]"
            >
              <li v-for="link in links" :key="link.langIndex">
                <router-link
                  @click="setLanguage(link.langIndex)"
                  class="hover:shadow-md flex items-center justify-center my-0.5 rounded-full"
                  :class="[
                    link.activeClass,
                    isBurgerActive
                      ? 'hover:bg-white hover:text-red-700 text-lg text-white px-4 pt-0.5 pb-1 mx-1'
                      : 'hover:bg-red-600 hover:text-white text-xs px-3 pt-1 pb-1.5 mx-0.5 ',
                  ]"
                  :to="{ path: getRelativePath(link.url) }"
                  >{{ link.langName }}</router-link
                >
              </li>
            </language-switcher>
          </transition>
        </div>
        <!-- Hamburger Button -->
        <!-- Анимация 1 -->
        <!-- <button 
          class="w-14 z-50 h-12 duration-200 border-2 border-opacity-0 rounded" 
        :class="[ isBurgerActive ? ' text-white border-white' : 'text-gray-500 hover:border-gray-500 hover:text-gray-500' ]"
          @click.prevent="toggle"
        >
          <span class="block w-8 h-1 mx-auto my-1.5 bg-current rounded transform duration-300" :class="{ 'rotate-45 translate-y-2.5' : isBurgerActive }"></span>
          <span class="block w-8 h-1 mx-auto my-1.5 bg-current rounded duration-200" :class="{ 'opacity-0' : isBurgerActive }"></span>
          <span class="block w-8 h-1 mx-auto my-1.5 bg-current rounded transform duration-300" :class="{ '-rotate-45 -translate-y-2.5' : isBurgerActive }"></span>
        </button> -->
        <!-- Анимация 2 -->
        <button
          aria-label="Меню"
          class=" ease absolute right-0 text-gray-500 transition-all duration-300"
          :class="[
            isBurgerActive
              ? 'z-40 transform -translate-x-1 rotate-45 w-10 h-10'
              : 'w-10 xl:w-12 h-10 xl:h-12 rounded',
            modalVisible ? 'hidden' : '',
          ]"
          @click.prevent="toggle"
        >
          <span
            class="burger-strip duration-300 ease-in-out transform"
            :class="[
              isBurgerActive
                ? 'w-6 xl:w-8 text-white translate-y-1 xl:translate-y-2'
                : 'w-6 xl:w-8',
            ]"
          ></span>
          <span
            class="burger-strip duration-200"
            :class="[isBurgerActive ? 'w-6 xl:w-8 opacity-0' : 'w-6 xl:w-8']"
          ></span>
          <span
            class="burger-strip duration-200 ease-in-out transform"
            :class="[
              isBurgerActive
                ? 'w-6 xl:w-8 text-white -translate-y-3 xl:-translate-y-3 rotate-90'
                : 'w-6 xl:w-8',
            ]"
          ></span>
        </button>

        <!-- Hamburger Menu -->
        <div v-if="isBurgerActive" class="fixed inset-0 z-30 overflow-y-auto">
          <burger-modal></burger-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setLanguage } from "../vue-zina-router";
import { defineComponent } from "vue";
import { defineAsyncComponent } from "vue";
import { mapGetters, mapMutations } from "vuex";
import debounce from "lodash/debounce";
const IconBase = defineAsyncComponent(() => import("@/components/IconBase"));
const IconZinaLogo = defineAsyncComponent(() => import("@/components/icons/IconZinaLogo"));
// import IconBase from '@/components/IconBase.vue'
// import IconZinaLogo from '@/components/icons/IconZinaLogo.vue'
const ButtonMessengers = defineAsyncComponent(() => import("@/components/UI/ButtonMessengers"));
const BurgerModal = defineAsyncComponent(() => import("@/components/UI/BurgerModal"));

export default defineComponent({
  name: "NavBar",
  setup() {
    // onMounted(() => console.log(setLanguage));
    return { setLanguage };
  },
  components: {
    ButtonMessengers,
    BurgerModal,
    IconBase,
    IconZinaLogo,
  },
  computed: {
    ...mapGetters({
      modalVisible: "modal/modalVisible",
      isBurgerActive: "select/selectIsBurgerActive",
      isBurgerOrderActive: "select/selectIsBurgerOrderActive",
      ifScroll: "select/ifScroll",
      // Получаем из стейта позицию активной секции
      active: "section/active",
    }),
  },

  data() {
    return {
      // ifScroll: false,
      handleDebouncedScroll: null,
    };
  },
  methods: {
    getRelativePath(path) {
      let url = path;
      let arrayPath = path.split("/");
      // console.log("getRelativePath: " + arrayPath);
      // console.log(typeof arrayPath);

      if (arrayPath.includes("uk")) {
        url = path.replace(/\/uk\//g, "/");
      } else if (arrayPath.includes("ru")) {
        // console.log("arrayPath.length: " + arrayPath.length);
        // console.log("DATA TEST: " + arrayPath[arrayPath.length - 1]);
        // console.log(typeof arrayPath[arrayPath.length - 1]);
        if (arrayPath[2].length !== 0) {
          url = path.replace(/\/ru\//g, "/");
        }
      }

      return url;
    },
    ...mapMutations({
      setIsBurgerActive: "select/setIsBurgerActive",
      setIBurgerOrderActive: "select/setIsBurgerOrderActive",
      setIfScroll: "select/setIfScroll",
    }),
    toggle() {
      this.setIsBurgerActive(!this.isBurgerActive);
    },
    handleScroll() {
      // console.log("window.scrollY: " + window.scrollY);
      if (window.scrollY > 1) {
        this.setIfScroll(true);
        // this.ifScroll = true;
      } else {
        this.setIfScroll(false);
        // this.ifScroll = false;
      }
      // console.log("ifScroll: " + this.ifScroll);
    },
  },
  mounted() {
    // Создание обработчика события и регистрация его. При маунте компонента.
    this.handleDebouncedScroll = debounce(this.handleScroll, 100);
    window.addEventListener("scroll", this.handleDebouncedScroll);
  },
  beforeUnmount() {
    // Удаленние обработчика при уничтожении компонента.
    window.removeEventListener("scroll", this.handleDebouncedScroll);
  },
});
</script>

<style lang="scss" scoped>
/* Появление модалки */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.main-menu__link {
  @apply hover:border-red-500 hover:text-red-600 lg:border-b-4 lg:border-white;
}
/* Смена телефона на кнопку заказа при скролле, и обратно */
.lang-enter-active,
.phone-or-button-enter-active {
  transition: opacity 1.5s ease;
}
.lang-leave-active,
.phone-or-button-leave-active {
  transition: opacity 0.2s ease;
}
.lang-enter-from,
.phone-or-button-enter-from,
.lang-leave-to,
.phone-or-button-leave-to {
  opacity: 0;
}

.hamburger-menu {
  clip-path: circle(30px at calc(100% - 100px) 47px);
}
.hamburger-menu_active {
  clip-path: circle(100%);
}

.burger-strip {
  @apply block h-0.5 xl:h-1 mx-auto my-1.5 bg-current rounded;
}

.facebook-messenger-bg:hover {
  background: linear-gradient(
    to bottom left,
    rgb(255, 85, 126) 0%,
    rgb(166, 53, 248) 50%,
    rgb(26, 137, 255) 100%
  );
}

// Hamburger Menu

.ease-out-overlay-enter-active,
.ease-out-overlay-leave-active {
  @apply opacity-100 duration-500;
}

.ease-out-overlay-enter,
.ease-out-overlay-leave-to {
  @apply opacity-0 duration-300;
}

.ease-out-modal-enter-active,
.ease-out-modal-leave-active {
  @apply opacity-100 translate-y-0 sm:scale-100 duration-300;
}

.ease-out-modal-enter, .ease-out-modal-leave-to /* .fade-leave-active below version 2.1.8 */ {
  @apply ease-in opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95 duration-200;
}
</style>
