export const sectionModule = {

    state() {
        return {
            active: null,
        }
    },

    getters: {
        active(state) {
            return state.active;
        }
    },

    mutations: {
        setActive(state, data) {
            state.active = data;
        }
    },

    actions: {

    },

    namespaced: true,

};