<template>
  <div 
    v-if="$screen.landscape"
    class="2xl:pb-4 absolute bottom-0 right-0 flex justify-center w-1/2 pb-2"
  >
    <button class="btn-next-section">
      <a 
        class="flex flex-col items-center" 
        href="#" 
        v-scroll-to="nextAnchor"
      >
        <div class="btn-next-section-rect-blue"></div>
        <div class="btn-next-section-rect-purple"></div>
        <div class="btn-next-section__title">
          <slot></slot>
        </div>
        <div class="btn-next-section__arrow">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.7 56.7">
            <g fill="#8969cb">
              <path d="M32.7 40.4 16.4 56.7 0 40.4l.7-.8 15.7 15.7L32 39.6Z"/>
              <path d="M16.9 56h-1V0h1Z"/>
            </g>
          </svg>
        </div>
      </a>
    </button>
    </div>
</template>

<script>
  export default {
    name: "button-next-section",
    props: {
      nextAnchor: {
        type: String,
      },
    },
  };
</script>

<style lang="scss">
  .btn-next-section {
    @apply relative block;
    width: 400px;
    @media (min-width: 1680px) {
      width: 490px;
    }
  }
  .btn-next-section-rect-blue,
  .btn-next-section-rect-purple,
  .btn-next-section-rect-yellow {
    transition: opacity 0.15s ease-in-out;
    height: 60px;
    @media (min-width: 1680px) {
      height: 73px;
    }
  }
  .btn-next-section-rect-blue {
    @apply absolute top-0 left-0;
    width: 312px;
    @media (min-width: 1680px) {
      width: 382px;
    }
  }
  .btn-next-section-rect-purple,
  .btn-next-section-rect-yellow {
    @apply absolute right-0;
    width: 344px;
    top: 16px;
    @media (min-width: 1680px) {
      width: 421px;
      top: 20px;
    }
  }
  .btn-next-section-rect-blue {
    @apply bg-picton-blue-500 bg-opacity-90;
  }
  .btn-next-section-rect-purple {
    @apply bg-zina-purple bg-opacity-90;
  }
  .btn-next-section-rect-yellow {
    @apply bg-zina-yellow bg-opacity-90;
  }
  .btn-next-section__title,
  .btn-next-section__title_purple {
    @apply z-10 w-full 2xl:mt-5 mt-4 text-center uppercase;
    font-size: 26px;
    @media (min-width: 1680px) {
      font-size: 32px;
    }
  }
  .btn-next-section__title {
    @apply text-white;
  }
  .btn-next-section__title_purple {
    @apply text-zina-purple;
  }
  .btn-next-section__arrow {
    width: 24px;
    @media (min-width: 1680px) {
      width: 32px;
    }
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
  }
  .btn-next-section:hover {
    .btn-next-section-rect-blue,
    .btn-next-section-rect-purple {
      opacity: 0.6;
    }
    .btn-next-section__arrow {
      transform: translateY(7px);
      @media (min-width: 1680px) {
        transform: translateY(10px);
      }
    }
  }
</style>
