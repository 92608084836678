<template>
  <div 
    class="flex flex-col justify-center w-full"
    :class="[
      $screen.landscape 
        ? 'pt-14 h-full' 
        : 'pt-16'
    ]"
  >
    <div 
      class="container-gradient absolute w-full"
      :class="[
        $screen.landscape 
          ? 'h-2/3 top-14 ' 
          : 'h-2/3 top-0'
      ]"
    ></div>
    <h2 
      class="font-roboto font-light tracking-tight text-center text-white"
      :class="[
        $screen.landscape 
          ? 'h2-title-bold__smaller 3xl:mb-16 mb-4' 
          : 'h2-title-portrait mb-12'
      ]"
    >
      <span v-if="$i18n.locale === 'uk'"><span class="block leading-tight uppercase">Нагороди, сертифікати</span> <span class="md:text-5xl font-extralight sm:text-4xl block text-3xl leading-none">та&nbsp;рекомендаційні листи</span></span>
      <span v-if="$i18n.locale === 'ru'"><span class="block leading-tight uppercase">Награды, сертификаты</span> <span class="md:text-5xl font-extralight sm:text-4xl block text-3xl leading-none">и&nbsp;рекомендательные письма</span></span>
    </h2>
    <div class="3xl:mb-24 land:pr-8 flex items-center mb-16">
      <Splide @splide="consoleText" :options="options">
        <SplideSlide v-for="(slide, index) in slides" :key="index">
          <a
            class="splide__slide-container draggable"
            @click.prevent="() => showImg(index)"
            href="#"
            :title="slide.title[$i18n.locale]"
          >
            <img
              class="slide-img"
              :data-splide-lazy="slide.image"
              :alt="slide.title[$i18n.locale]"
            />
          </a>
        </SplideSlide>
        <template #before-track>
          <div class="splide__arrows text-white">
            <button class="splide__arrow splide__arrow--prev">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 60" width="20" height="60">
                <path fill="currentColor" d="M3.7 60 0 58.1 15.3 30 0 1.9 3.7 0 20 30 3.7 60z"/>
              </svg>
            </button>
            <button class="splide__arrow splide__arrow--next">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 60" width="20" height="60">
                <path fill="currentColor" d="M3.7 60 0 58.1 15.3 30 0 1.9 3.7 0 20 30 3.7 60z"/>
              </svg>
            </button>
          </div>
        </template>
        <template #after-track>
          <div class=" fade-overlay fade-overlay__left from-gray-50 sm:w-12 lg:w-16 2xl:w-24 w-8"></div>
          <div class=" fade-overlay fade-overlay__right from-gray-50 sm:w-12 lg:w-16 2xl:w-24 w-8"></div>
        </template>
      </Splide>
    </div>
  </div>
  <!-- Лайт-бокс картинок, выводятся по index в массиве -->
  <vue-easy-lightbox
    :visible="visible"
    :imgs="[
      '/home/img/awards/980/hostmaster.webp',
      '/home/img/awards/980/tag-line-creative.webp',
      '/home/img/awards/980/tag-line-special.webp',
      '/home/img/awards/980/teneta-i.webp',
      '/home/img/awards/980/teneta-iii.webp',
      '/home/img/awards/980/daewoo.webp',
      '/home/img/awards/980/metalika.webp',
      '/home/img/awards/980/lifelab.webp',
      '/home/img/awards/980/dal-museum.webp',
      '/home/img/awards/980/schastye-rada.webp',
      '/home/img/awards/980/victor-invest-site.webp',
      '/home/img/awards/980/victor-invest-firm-style.webp',
      '/home/img/awards/980/amkrprof.webp',
      '/home/img/awards/980/mariupol.webp',
    ]"
    :index="index"
    @hide="handleHide"
  ></vue-easy-lightbox>
</template>

<script>
// import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { defineComponent } from "vue";
// import "@splidejs/splide/dist/css/splide-core.min.css";

const data = [
  {
    image: "/home/img/awards/hostmaster-300x424.webp",
    title: {
      uk: "Сертифікат офіційного реєстратора доменів в Україні",
      ru: "Сертификат официального регистратора доменов в Украине",
    },
  },
  {
    image: "/home/img/awards/tag-line-creative-300x424.webp",
    title: {
      uk: "Шорт-лист конкурсу «TagLine» у номінації «Найкращий креатив» за сайт Студії «Зіна дизайн»",
      ru: "Шорт-лист конкурса «TagLine» в номинации «Лучший креатив» за сайт Студии «Зина дизайн»",
    },
  },
  {
    image: "/home/img/awards/tag-line-special-300x424.webp",
    title: {
      uk: "Спеціальний приз конкурсу «TagLine» у номінації «Альтернативний підхід до графічної концепції» за сайт Студії «Зіна дизайн»",
      ru: "Специальный приз конкурса «TagLine» в номинации «Альтернативный подход к графической концепции» за сайт студии «Зина дизайн»",
    },
  },
  {
    image: "/home/img/awards/teneta-i-300x424.webp",
    title: {
      uk: "1-е місце на конкурсі «Тенета-Рінет 1998-1999» у номінації «Мережеві літературні проекти: Системно-монографічний мережевий проект»",
      ru: "1-е место на конкурсе «Тенета-Ринет 1998-1999» в номинации «Сетевые литературные проекты: Системно-монографический сетевой проект»",
    },
  },
  {
    image: "/home/img/awards/teneta-iii-300x424.webp",
    title: {
      uk: "3-е місце на конкурсі «Тенета-Рінет 1998-1999» у номінації «Мережеві літературні проекти: Електронний літературний журнал/газета»",
      ru: "3-е место на конкурсе «Тенета-Ринет 1998-1999» в номинации «Сетевые литературные проекты: Электронный литературный журнал/газета»",
    },
  },
  {
    image: "/home/img/awards/daewoo-300x424.webp",
    title: {
      uk: "Подяка від компанії «ДЕУ Електронікс Трейдінг Компані» за створення сайту www.daewoo.ua",
      ru: "Благодарность от компании «ДЭУ Электроникс Трейдинг Компани» за создание сайта www.daewoo.ua",
    },
  },
  {
    image: "/home/img/awards/metalika-300x424.webp",
    title: {
      uk: "Диплом ІІІ всеукраїнського інтернет-конкурсу «Найкращий сайт»",
      ru: "Диплом III всеукраинского интернет-конкурса «Лучший сайт»",
    },
  },
  {
    image: "/home/img/awards/lifelab-300x424.webp",
    title: {
      uk: "Рекомендаційний лист від «Лабораторії якості життя» Дніпропетровської обласної ради",
      ru: "Рекомендательное письмо от «Лаборатории качества жизни» Днепропетровского областного совета",
    },
  },
  {
    image: "/home/img/awards/dal-museum-300x208.webp",
    title: {
      uk: "Подяка від Міського музею історії та культури Луганська за створення сайту «Літературний музей В.І.Даля»",
      ru: "Благодарность от Городского музея истории и культуры Луганска за создание сайта «Литературный музей В.И.Даля»",
    },
  },
  {
    image: "/home/img/awards/schastye-rada-300x424.webp",
    title: {
      uk: "Рекомендаційний лист від Щастінської міської ради",
      ru: "Рекомендательное письмо от Счастьинского городского совета",
    },
  },
  {
    image: "/home/img/awards/victor-invest-site-300x424.webp",
    title: {
      uk: "Подяка від компанії «Віктор-Інвест» за розробку сайту",
      ru: "Благодарность от компании «Виктор-Инвест» за разработку сайта",
    },
  },
  {
    image: "/home/img/awards/victor-invest-firm-style-300x424.webp",
    title: {
      uk: "Подяка від компанії «Віктор-Інвест» за розробку фірмового стилю",
      ru: "Благодарность от компании «Виктор-Инвест» за разработку фирменного стиля",
    },
  },
  {
    image: "/home/img/awards/amkrprof-300x424.webp",
    title: {
      uk: "Рекомендаційний лист від «АрселорМіттал Кривий Ріг»",
      ru: "Рекомендательное письмо от «АрселорМиттал Кривой Рог»",
    },
  },
  {
    image: "/home/img/awards/mariupol-300x424.webp",
    title: {
      uk: "Рекомендаційний лист від Маріупольської міської ради",
      ru: "Рекомендательное письмо от Мариупольского городского совета",
    },
  },
];

export default defineComponent({
  name: "AwardsBlock",
  setup() {
    const slides = data;
    const options = {
      width: "100%",
      // autoWidth: true,
      speed: "800",
      type: "loop",
      autoplay: false,
      focus: "center",
      updateOnMove: true,
      pagination: false,
      lazyLoad: "nearby",
      preloadPages: "1",
      perMove: 1,
      perPage: 4,
      breakpoints: {
        2048: {
          perPage: 5,
        },
        1536: {
          perPage: 4,
        },
        1366: {
          perPage: 3,
        },
        1024: {
          perPage: 3,
        },
        640: {
          perPage: 2,
        },
        428: {
          perPage: 1,
        },
      },
      classes: {
        arrow: "splide__arrow splide__arrow-fade",
        prev: "splide__arrow--prev splide__arrow-fade--prev",
        next: "splide__arrow--next splide__arrow-fade--next",
      },
    };

    return { options, slides };
  },
  data() {
    return {
      visible: false,
      index: 0, // default: 0
    };
  },
  methods: {
    consoleText(ev) {
      console.log("Hello : " + ev);
    },
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },
});
</script>

<style lang="scss" scoped>

  .slide-img {
    @apply object-cover;
    @media (orientation: landscape) {
      max-height: 300px;
    }
    @media (min-width: 1680px) { 
      max-height: none;
    }
  }

  .splide__slide-container {
    @apply shadow-xl block;
    transition: transform 400ms;
  }

  .splide__pagination-design-desktop {
    padding-left: 4rem;
    @media (min-width: 1850px) {
      bottom: 30px !important;
    }
  }
  .splide__slide {
    @apply flex justify-center items-center pt-2 pb-10;
  }

  /* Splide Arrows */

  .splide__arrow {
    @apply bg-transparent;
  }
  .splide__arrow svg {
    width: 20px;
    height: 60px;
  }
  .splide__arrow.splide__arrow--prev {
    @apply left-0;
  }
  .splide__arrow.splide__arrow--next {
    @apply right-0;
  }

  
</style>

