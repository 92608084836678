<template>
  <svg xmlns="http://www.w3.org/2000/svg" 
    :width="width" 
    :height="height" 
    :viewBox="viewBox" 
    :aria-labelledby="title" 
    role="presentation"
  >
    <title :lang="$i18n.locale">{{ title }}</title>
    <desc v-if="desc" :lang="$i18n.locale">{{ desc }}</desc>
    <g :fill="iconColor">
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Иконка'
    },
    desc: {
      type: String,
    },
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    viewBox: {
      type: String,
      default: '0 0 32 32'
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    },
  }
}
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; 
  /* yes, I'm that particular about formatting */
}
</style>