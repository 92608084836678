<template>
  <div 
    class="w-full h-full pb-16"
    :class="[
      $screen.landscape 
        ? '' 
        : 'pt-24'
    ]"
  >
    <div class="sea-container flex flex-col justify-between w-full h-full">
      <div 
        class="flex"
        :class="[
          $screen.landscape 
            ? 'items-end justify-end flex-grow w-1/2' 
            : 'justify-center'
        ]"
      >
        <h2 
          class=" font-roboto tracking-tight uppercase"
          :class="[
            $screen.landscape 
              ? 'h2-title-bold 3xl:mb-12 mb-4 text-right ' 
              : 'h2-title-portrait mb-12'
          ]"
        >
          <span v-if="$i18n.locale === 'uk'">
            <span class="font-extralight text-picton-blue-500">Колосальний</span><br><span class="text-zina-purple font-light">досвід</span>
          </span>
          <span v-if="$i18n.locale === 'ru'">
            <span class="font-extralight text-picton-blue-500">Колоссальный</span><br><span class="text-zina-purple font-light">опыт</span>
          </span>
        </h2>
      </div>
      <div 
        v-if="$screen.portrait"
        class="description-main mb-20"
      >
        <p v-if="$i18n.locale === 'uk'">
          Перші міжнародні нагороди за&nbsp;створені сайти студія «Зіна&nbsp;дизайн» отримала ще в&nbsp;минулому тисячолітті 😱
        </p>
        <p v-if="$i18n.locale === 'ru'">
          Первые международные награды за&nbsp;созданные сайты студия «Зина&nbsp;дизайн» получила ещё в&nbsp;прошлом тысячелетии 😱
        </p>
      </div>
      <div class="sea-bottom 2xl:pb-8 flex text-white">
        <div 
          class="flex items-center h-full"
          :class="[
            $screen.landscape 
              ? 'flex-col justify-center w-1/2' 
              : 'flex-wrap justify-evenly w-full'
          ]"
        >
          <div 
            class="font-roboto font-extralight text-6xl text-center"
            :class="[
              $screen.landscape 
                ? 'mb-8' 
                : 'mx-6'
            ]"
          >
            <p v-if="$i18n.locale === 'uk'"><span class="block uppercase" style="font-size: 64px;">Працюємо</span> з <span class="text-zina-yellow font-light">1998 року</span></p>
            <p v-if="$i18n.locale === 'ru'"><span class="block uppercase" style="font-size: 64px;">Работаем</span> с <span class="text-zina-yellow font-light">1998 года</span></p>
          </div>
          <div class="font-roboto font-light">
            <span class="flex">
              <span class="text-zina-yellow mr-4 leading-none" style="font-size: 150px;">4</span>
              <span class="pt-2 uppercase" style="font-size: 56px; line-height: 1.2">
                <span v-if="$i18n.locale === 'uk'">перших<br>місця</span>
                <span v-if="$i18n.locale === 'ru'">первых<br>места</span>
              </span>
            </span>
            <span class="block text-xl text-center">
              <span v-if="$i18n.locale === 'uk'">на міжнародних конкурсах</span>
              <span v-if="$i18n.locale === 'ru'">на международных конкурсах</span>
            </span>
          </div>
        </div>
        <div 
          v-if="$screen.landscape"
          class="flex flex-col justify-center w-1/2 h-full"
        >
          <div class="font-ptsans 2xl:mb-10 2xl:leading-relaxed mb-4 text-3xl" style="max-width: 600px;">
            <p v-if="$i18n.locale === 'uk'">
              Перші міжнародні нагороди за&nbsp;створені сайти студія «Зіна&nbsp;дизайн» отримала ще в&nbsp;минулому тисячолітті 😱
            </p>
            <p v-if="$i18n.locale === 'ru'">
              Первые международные награды за&nbsp;созданные сайты студия «Зина&nbsp;дизайн» получила ещё в&nbsp;прошлом тысячелетии 😱
            </p>
          </div>
          <div class="2xl:mb-0 max-w-2xl mb-8 text-lg">
            <div v-if="$i18n.locale === 'uk'">
              <p class="mb-2">За ці 20+ років веб-розробки ми наступили на більшу кількість <span class="whitespace-nowrap">веб-дизайнерських</span> граблів, ніж <span class="whitespace-nowrap">будь-яка</span> «молода амбітна команда професіоналів».</p>
              <p class="mb-2">Зараз ми просто беремо і&nbsp;робимо круті сайти.</p>
              <!-- <p class=" 2xl:block hidden">Сайти максимальної якості.</p> -->
            </div>
            <div v-if="$i18n.locale === 'ru'">
              <p class="mb-2">За эти 20+ лет веб-разработки мы наступили на бóльшее количество <span class="whitespace-nowrap">веб-дизайнерских</span> граблей, чем любая «молодая амбициозная команда профессионалов».</p>
              <p class="mb-2">Сейчас мы просто берем и&nbsp;делаем крутые сайты.</p>
              <!-- <p class="">Сайты максимального качества.</p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div 
      v-if="$screen.portrait"
      class="max-w-2xl px-4 mx-auto mt-8 mb-8 text-lg"
    >
      <div v-if="$i18n.locale === 'uk'">
        <p class="mb-2">За ці 20+ років веб-розробки ми наступили на більшу кількість <span class="whitespace-nowrap">веб-дизайнерських</span> граблів, ніж <span class="whitespace-nowrap">будь-яка</span> «молода амбітна команда професіоналів».</p>
        <p class="mb-2">Зараз ми просто беремо і&nbsp;робимо сайти.</p>
        <p class="">Сайти максимальної якості.</p>
      </div>
      <div v-if="$i18n.locale === 'ru'">
        <p class="mb-2">За эти 20+ лет веб-разработки мы наступили на бóльшее количество <span class="whitespace-nowrap">веб-дизайнерских</span> граблей, чем любая «молодая амбициозная команда профессионалов».</p>
        <p class="mb-2">Сейчас мы просто берем и&nbsp;делаем сайты.</p>
        <p class="">Сайты максимального качества.</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ExperienceBlock",
  };
</script>

<style lang="scss" scoped>

  .sea-container {
    background: 
      url(/home/img/plavali-cropped.webp) bottom -100px center no-repeat,
      url(/home/img/plavali-repeat.webp) bottom -100px center repeat-x;
    @media (min-width: 1680px) { 
      background: 
        url(/home/img/plavali-cropped.webp) bottom center no-repeat,
        url(/home/img/plavali-repeat.webp) bottom center repeat-x;
    }
    @media (orientation: portrait) {
      background: 
        url(/home/img/plavali-cropped.webp) bottom -100px left 80% no-repeat,
    }
  }

  .sea-bottom {
    height: 400px;
    @media (min-width: 1680px) { 
      height: 500px;
    }
  }

</style>