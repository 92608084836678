import { createWebHistory } from 'vue-router'
import translations from '../lang/translations'
import localizedURLs from '../lang/localized-urls'
import { createLangRouter } from '../vue-zina-router'
// import { useI18n } from "vue-i18n";

//Pages
const Home = () => import('@/views/Home.vue');
// const About = () => import('@/views/About.vue');
// const Portfolio = () => import('@/views/Portfolio.vue');
// const Prices = () => import('@/views/Prices.vue');
// const Contacts = () => import('@/views/Contacts.vue');
const Error = () => import('@/views/Error.vue');

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    // meta: { title: i18n.t('title') }
    // meta: {
    //   title: 'Home Page - Example App',
    //   metaTags: [
    //     {
    //       name: 'description',
    //       content: 'The home page of our example app.'
    //     },
    //     {
    //       property: 'og:description',
    //       content: 'The home page of our example app.'
    //     }
    //   ]
    // }
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: About
  // },
  // {
  //   path: '/portfolio',
  //   name: 'Portfolio',
  //   component: Portfolio
  // },
  // {
  //   path: '/prices',
  //   name: 'Prices',
  //   component: Prices
  // },
  // {
  //   path: '/contacts',
  //   name: 'Contacts',
  //   component: Contacts
  // },
  {
    path: '/:pathMatch(.*)*',
    name: 'Error',
    component: Error,
  },
]

const langRouterOptions = {
  defaultLanguage: 'uk',
  translations,
  localizedURLs,
}
const routerOptions = {
  history: createWebHistory(process.env.BASE_URL),
  routes
}
const router = createLangRouter(langRouterOptions, routerOptions)

export default router
